import diamond from '../../shader/fragment/diamond.glsl?raw'
import waterColor from '../../shader/fragment/waterColor.glsl?raw'
import noise from '../../shader/fragment/noise.glsl?raw'
import circlePulse from '../../shader/fragment/circlePulse.glsl?raw'
import circlePulse2 from '../../shader/fragment/circlePulse2.glsl?raw'
import circlePulse3 from '../../shader/fragment/circlePulse3.glsl?raw'
import swirl from '../../shader/fragment/swirl.glsl?raw'
import circle from '../../shader/fragment/circle.glsl?raw'
import circleLight from '../../shader/fragment/circleLight.glsl?raw'
import slime from '../../shader/fragment/slime.glsl?raw'
import soup from '../../shader/fragment/soup.glsl?raw'
import frag1 from '../../shader/fragment/frag1.glsl?raw'
import frag2 from '../../shader/fragment/frag2.glsl?raw'
import gallaxy from '../../shader/fragment/gallaxy.glsl?raw'
import clouds from '../../shader/fragment/clouds.glsl?raw'
import optical from '../../shader/fragment/optical.glsl?raw'

import kale1 from '../../shader/fragment/kale1.glsl?raw'
import kale2 from '../../shader/fragment/kale2.glsl?raw'
import kale3 from '../../shader/fragment/kale3.glsl?raw'
import kale4 from '../../shader/fragment/kale4.glsl?raw'
import kale7 from '../../shader/fragment/kale7.glsl?raw'
import kale8 from '../../shader/fragment/kale8.glsl?raw'
import kale9 from '../../shader/fragment/kale9.glsl?raw'
import kale10 from '../../shader/fragment/kale10.glsl?raw'


import waterColor2 from '../../shader/fragment/waterColor2.glsl?raw'
import waterColor3 from '../../shader/fragment/waterColor3.glsl?raw'

import donut from '../../shader/fragment/donut.glsl?raw'
import bubbleGum from '../../shader/fragment/bubblegum.glsl?raw'
import virus from '../../shader/fragment/virus.glsl?raw'

import poly from '../../shader/fragment/poly.glsl?raw'
import poly2 from '../../shader/fragment/poly2.glsl?raw'
import poly3 from '../../shader/fragment/poly3.glsl?raw'
import poly4 from '../../shader/fragment/poly4.glsl?raw'

import square from '../../shader/fragment/square.glsl?raw';

import vasarely1 from '../../shader/fragment/vasarely1.glsl?raw';
import dot from '../../shader/fragment/dot.glsl?raw';
import lines from '../../shader/fragment/lines.glsl?raw';
import lines2 from '../../shader/fragment/lines2.glsl?raw';

import noiseColor from '../../shader/fragment/noiseColor.glsl?raw';
import noiseColor2 from '../../shader/fragment/noiseColor2.glsl?raw';
import noiseColor3 from '../../shader/fragment/noiseColor3.glsl?raw';

const configScreen = {
  elems: [
    {
      name: 'Flashing Light',
      frag: noiseColor,
      count: 1,
      countnull: 1,
      yAngle: [
        {
          name: '_0000',
          value: 180
        }
      ],
      raycastRange : [
        {
          start: 0,
          end: 29.60
        },
        {
          start: 49.4,
          end: 99
        }
      ]
    },
    {
      name: 'Water Color',
      frag: waterColor,
      count: 9,
      countnull: 9,
      raycastRange : [
        {
          start: 96.64,
          end: 99.99
        },
        {
          start: 0,
          end: 2.73
        }
      ],
      yAngle: [
        {
          name: '_1000',
          value: 104,
          zoom: 0.75
        },
        {
          name: '_1001',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_1002',
          value: -70,
          zoom: 0.85
        },
        {
          name: '_1003',
          value: -73.1,
          zoom: 0.75
        },
        {
          name: '_1004',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_1005',
          value: 104,
          zoom: 0.75
        },
        {
          name: '_1006',
          value: 104,
          zoom: 0.65
        },
        {
          name: '_1007',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_1008',
          value: -70,
          zoom: 0.75
        }
      ],
      params: [
        {
          name: 'Division',
          nameUniform: 'u_division',
          min: 0,
          max: 30,
          type: 'number',
          baseValue: 6,
        },
        {
          name: 'Scale',
          nameUniform: 'u_scaleCoord',
          min: 0,
          max: 15,
          type: 'number',
          decimal: 2,
          baseValue: 6,
        },
      ]
    },
    {
      name: 'Colorful Polygon',
      frag: frag2,
      count: 7,
      countnull: 7,
      params: [
        {
          name: 'Overlay Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#ffed04',
        },
        {
          name: 'Background Color',
          nameUniform: 'u_colorSecond',
          type: 'color',
          baseValue: '#0058ff',
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 6,
        },
      ],
      raycastRange : [
        {
          start: 0,
          end: 2.73
        }
      ],
      yAngle: [
        {
          name: '_2000',
          value: -73.3,
          zoom: 0.5
        },
        {
          name: '_2001',
          value: 113,
          zoom: 0.65
        },
        {
          name: '_2002',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_2003',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_2004',
          value: -70,
          zoom: 0.75
        },
        {
          name: '_2005',
          value: -70,
          zoom: 0.65
        },
        {
          name: '_2006',
          value: 110,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Slime',
      frag: slime,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Noise',
          nameUniform: 'u_octaves',
          min: 0,
          max: 3,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Contrast',
          nameUniform: 'u_colorF',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Specular Power',
          nameUniform: 'u_specular',
          min: 0,
          max: 30,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Generation',
          nameUniform: 'u_power',
          min: 0,
          max: 100,
          decimal: 3,
          type: 'number',
          baseValue: 43,
        },
        {
          name: 'color1',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#5458fe',
        },
      ],
      raycastRange : [
        {
          start: 2.73,
          end: 7.89
        },
        {
          start: 59.64,
          end: 61.11
        }
      ],
      yAngle: [
        {
          name: '_3000',
          value: 80,
          zoom: 0.75
        },
        {
          name: '_3001',
          value: 115,
          zoom: 0.65
        },
        {
          name: '_3002',
          value: -20,
          zoom: 0.75
        },
        {
          name: '_3003',
          value: -32.5,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Soup',
      frag: soup,
      count: 8,
      countnull: 8,
      params: [
        {
          name: 'Noise',
          nameUniform: 'u_octaves',
          min: 0,
          max: 3,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Generation',
          nameUniform: 'u_seed',
          min: 0,
          max: 100,
          decimal: 2,
          type: 'number',
          baseValue: 43,
        },
        {
          name: 'Zoom',
          nameUniform: 'u_zoom',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 3,
        },
        {
          name: 'Multiply',
          nameUniform: 'u_multiply',
          min: 0,
          max: 30,
          decimal: 2,
          type: 'number',
          baseValue: 15,
        },
      ],
      raycastRange : [
        {
          start: 7.89,
          end: 18.06
        }
      ],
      yAngle: [
        {
          name: '_4000',
          value: 149,
          zoom: 0.8
        },
        {
          name: '_4001',
          value: -31,
          zoom: 0.75
        },
        {
          name: '_4002',
          value: -28,
          zoom: 0.75
        },
        {
          name: '_4003',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_4004',
          value: -30,
          zoom: 0.75
        },
        {
          name: '_4005',
          value: 151.1,
          zoom: 0.85
        },
        {
          name: '_4006',
          value: 157,
          zoom: 0.85
        },
        {
          name: '_4007',
          value: -26,
          zoom: 0.85
        }
      ]
    },
    {
      name: 'Diamond',
      frag: diamond,
      count: 6,
      countnull: 6,
      params: [
        {
          name: 'Luminosity',
          nameUniform: 'u_br',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 4,
        },
        {
          name: 'depth',
          nameUniform: 'u_depth',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 5,
        },
      ],
      raycastRange : [
        {
          start: 7.89,
          end: 18.06
        }
      ],
      yAngle: [
        {
          name: '_5000',
          value: -21,
          zoom: 0.85
        },
        {
          name: '_5001',
          value: 155,
          zoom: 0.8
        },
        {
          name: '_5002',
          value: -14,
          zoom: 0.75
        },
        {
          name: '_5003',
          value: 155,
          zoom: 0.85
        },
        {
          name: '_5004',
          value: 155,
          zoom: 0.85
        },
        {
          name: '_5005',
          value: 155,
          zoom: 0.8
        }
      ]
    },
    {
      name: 'Gradient Polygon',
      frag: poly3,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Gradient Color 2',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#000000',
        },
        {
          name: 'Gradient Color 2',
          nameUniform: 'u_colorSecond',
          type: 'color',
          baseValue: '#1458AB',
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 3,
        }
      ],
      raycastRange : [
        {
          start: 7.89,
          end: 18.06
        }
      ],
      yAngle: [
        {
          name: '_6000',
          value: 155,
          zoom: 0.85
        },
        {
          name: '_6001',
          value: -15,
          zoom: 0.85
        },
        {
          name: '_6002',
          value: -15,
          zoom: 0.85
        },
        {
          name: '_6003',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_6004',
          value: 155,
          zoom: 0.85
        }
      ]
    },
    {
      name: 'Coloful Waves',
      frag: noiseColor,
      count: 8,
      countnull: 8,
      params: [
        {
          name: 'Color Palette A',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#da2569',
        },
        {
          name: 'Color Palette B',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#c82d2d',
        },
        {
          name: 'Color Palette D',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#0a0ada',
        },
        {
          name: 'Global Amount',
          nameUniform: 'u_amount',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Global Ondulation',
          nameUniform: 'u_octaves',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 6,
        },
        {
          name: 'Wave A Pos X',
          nameUniform: 'u_utimeXA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.1254,
        },
        {
          name: 'Wave A Pos Y',
          nameUniform: 'u_utimeYA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.375,
        },
        {
          name: 'Wave A Scale',
          nameUniform: 'u_scaleA',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 4.57,
        },
        {
          name: 'Wave A Amount',
          nameUniform: 'u_numberA',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1.25,
        },
        {
          name: 'Wave B Pos X',
          nameUniform: 'u_utimeXB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.8654,
        },
        {
          name: 'Wave B Pos Y',
          nameUniform: 'u_utimeYB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.175,
        },
        {
          name: 'Wave B Scale',
          nameUniform: 'u_scaleB',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 5.74,
        },
        {
          name: 'Wave B Amount',
          nameUniform: 'u_numberB',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1.3,
        },
        {
          name: 'Wave C Pos X',
          nameUniform: 'u_utimeXC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.8654,
        },
        {
          name: 'Wave C Pos Y',
          nameUniform: 'u_utimeYC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.175,
        },
        {
          name: 'Wave C Scale',
          nameUniform: 'u_scaleC',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 5.74,
        },
        {
          name: 'Wave C Size X',
          nameUniform: 'u_xC',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 2.5,
        },
        {
          name: 'Wave C Size Y',
          nameUniform: 'u_yC',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 2.5,
        },
        {
          name: 'Wave C Amount',
          nameUniform: 'u_numberC',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1.3,
        }
      ],
      raycastRange : [
        {
          start: 7.89,
          end: 18.06
        }
      ],
      yAngle: [
        {
          name: '_7000',
          value: -15,
          zoom: 0.65
        },
        {
          name: '_7001',
          value: -30,
          zoom: 0.65
        },
        {
          name: '_7002',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_7003',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_7004',
          value: -15,
          zoom: 0.65
        },
        {
          name: '_7005',
          value: 142.5,
          zoom: 0.65
        },
        {
          name: '_7006',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_7007',
          value: -35,
          zoom: 0.65
        }
      ]
    },
    {
      name: 'Split Circle',
      frag: circlePulse,
      count: 7,
      countnull: 7,
      params: [
        {
          name: 'Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#da2569',
        },
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 5,
          type: 'number',
          baseValue: 1,
        },
      ],
      raycastRange : [
        {
          start: 18.06,
          end: 23.01
        }
      ],
      yAngle: [
        {
          name: '_8000',
          value: 70
        },
        {
          name: '_8001',
          value: 70
        },
        {
          name: '_8002',
          value: -115
        },
        {
          name: '_8003',
          value: 70
        },
        {
          name: '_8004',
          value: -70
        },
        {
          name: '_8005',
          value: 21.1
        },
        {
          name: '_8006',
          value: -115
        }
      ]
    },
    {
      name: 'Noise',
      frag: noise,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Scale',
          nameUniform: 'u_scaleEl',
          min: 0,
          max: 7,
          decimal: 2,
          type: 'number',
          baseValue: 3,
        },
        {
          name: 'Difraction',
          nameUniform: 'u_df',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 0,
        },
        {
          name: 'amount',
          nameUniform: 'u_amount',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 2.25,
        },
        {
          name: 'Min Red',
          nameUniform: 'u_minRed',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.1,
        },
        {
          name: 'Max Red',
          nameUniform: 'u_maxRed',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.45,
        },
        {
          name: 'Min Green',
          nameUniform: 'u_minGreen',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.3,
        },
        {
          name: 'Max Green',
          nameUniform: 'u_maxGreen',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.35,
        },
        {
          name: 'Min Blue',
          nameUniform: 'u_minBlue',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.3,
        },
        {
          name: 'Max Blue',
          nameUniform: 'u_maxBlue',
          min: 0,
          max: 1,
          decimal: 2,
          type: 'number',
          baseValue: 0.45,
        },
      ],
      raycastRange : [
        {
          start: 18.06,
          end: 23.01
        }
      ],
      yAngle: [
        {
          name: '_9000',
          value: 70
        },
        {
          name: '_9001',
          value: 70
        },
        {
          name: '_9002',
          value: -110
        },
        {
          name: '_9003',
          value: -115
        },
        {
          name: '_9004',
          value: -110
        }
      ]
    },
    {
      name: 'Spiral',
      frag: swirl,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 18.06,
          end: 23.01
        }
      ],
      yAngle: [
        {
          name: '_10000',
          value: 70,
          zoom: 0.35
        },
        {
          name: '_10001',
          value: 70,
          zoom: 0.35
        },
        {
          name: '_10002',
          value: 70,
          zoom: 0.35
        },
        {
          name: '_10003',
          value: -110,
          zoom: 0.35
        }
      ]
    },
    {
      name: 'Colorful Polygon 2',
      frag: poly,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 8,
        },
        {
          name: 'Stroke',
          nameUniform: 'u_stroke',
          min: 0,
          max: 1,
          decimal: 4,
          type: 'number',
          baseValue: 0.5,
        },
        {
          name: 'Background Color',
          nameUniform: 'u_colorSecond',
          type: 'color',
          baseValue: '#ed2103',
        },
      ],
      raycastRange : [
        {
          start: 29.49,
          end: 31.27
        }
      ],
      yAngle: [
        {
          name: '_11000',
          value: -5.5,
          zoom: 0.125
        },
        {
          name: '_11001',
          value: -90,
          zoom: 0.75
        },
        {
          name: '_11002',
          value: -182.5,
          zoom: 0.125
        }
      ]
    },
    {
      name: 'Colorful Polygon 3',
      frag: poly2,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Stroke',
          nameUniform: 'u_stroke',
          min: 0,
          max: 1,
          decimal: 4,
          type: 'number',
          baseValue: 0.5,
        },
        {
          name: 'Overlay Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#e76fd0',
        },
        {
          name: 'Background Color',
          nameUniform: 'u_colorSecond',
          type: 'color',
          baseValue: '#530595',
        },
      ],
      raycastRange : [
        {
          start: 18.06,
          end: 23.01
        },
        {
          start: 80.19,
          end: 81.39
        }
      ],
      yAngle: [
        {
          name: '_12000',
          value: -110,
          zoom: 0.85
        },
        {
          name: '_12001',
          value: -110,
          zoom: 0.85
        },
        {
          name: '_12002',
          value: -110,
          zoom: 0.85
        },
        {
          name: '_12003',
          value: 70,
          zoom: 0.25
        }
      ]
    },
    {
      name: 'BubbleGum',
      frag: bubbleGum,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Color Brightness',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#ec57e7',
        },
        {
          name: 'Color Contrast',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#ff1c9d',
        },
        {
          name: 'Color Oscillation',
          nameUniform: 'u_colorC',
          type: 'color',
          baseValue: '#000000',
        },
        {
          name: 'Color Phase',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#7837da',
        },
        {
          name: 'Wrap Scale',
          nameUniform: 'u_wrapScale',
          min: 0,
          max: 4,
          decimal: 2,
          type: 'number',
          baseValue: 1.5,
        },
      ],
      raycastRange : [
        {
          start: 31.27,
          end: 45.00
        }
      ],
      yAngle: [
        {
          name: '_13000',
          value: 90,
          zoom: 0.75
        },
        {
          name: '_13001',
          value: 90,
          zoom: 0.75
        },
        {
          name: '_13002',
          value: 90,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Virus',
      frag: virus,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Color Brightness',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#201f1f',
        },
        {
          name: 'Color Contrast',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#b35c5c',
        },
        {
          name: 'Color Oscillation',
          nameUniform: 'u_colorC',
          type: 'color',
          baseValue: '#006f6e',
        },
        {
          name: 'Color Phase',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#67e613',
        },
        {
          name: 'Wrap Scale',
          nameUniform: 'u_wrapScale',
          min: 0,
          max: 4,
          decimal: 2,
          type: 'number',
          baseValue: 1.5,
        },
      ],
      raycastRange : [
        {
          start: 31.27,
          end: 45.00
        }
      ],
      yAngle: [
        {
          name: '_14000',
          value: 0,
          zoom: 0.65
        },
        {
          name: '_14001',
          value: 2.5,
          zoom: 0.25
        },
        {
          name: '_14002',
          value: 2.5,
          zoom: 0.65
        }
      ]
    },
    {
      name: 'Donut',
      frag: donut,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Color Brightness',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#db1a1a',
        },
        {
          name: 'Color Contrast',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#7f7f7f',
        },
        {
          name: 'Color Oscillation',
          nameUniform: 'u_colorC',
          type: 'color',
          baseValue: '#ffffff',
        },
        {
          name: 'Color Phase',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#8f0101',
        },
        {
          name: 'Wrap Scale',
          nameUniform: 'u_wrapScale',
          min: 0,
          max: 4,
          decimal: 2,
          type: 'number',
          baseValue: 1,
        },
      ],
      raycastRange : [
        {
          start: 31.27,
          end: 45.00
        }
      ],
      yAngle: [
        {
          name: '_15000',
          value: -90
        },
        {
          name: '_15001',
          value: -90,
          zoom: 0.75
        },
        {
          name: '_15002',
          value: -90,
          zoom: 0.35
        }
      ]
    },
    {
      name: 'Rotating Rays',
      frag: circlePulse2,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1,
        },
      ],
      raycastRange : [
        {
          start: 46.54,
          end: 48.39
        }
      ],
      yAngle: [
        {
          name: '_16000',
          value: 2,
          zoom: 0.25
        },
        {
          name: '_16001',
          value: -180,
          zoom: 0.25
        },
        {
          name: '_16002',
          value: 90
        }
      ]
    },
    {
      name: 'Hypnotical Colors',
      frag: circlePulse3,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1,
        },
        {
          name: 'Angle',
          nameUniform: 'u_angle',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 3,
        }
      ],
      raycastRange : [
        {
          start: 53.6,
          end: 59.64
        },
        {
          start: 96.16,
          end: 97.86
        }
      ],
      yAngle: [
        {
          name: '_17000',
          value: 16.8,
          zoom: 0.6
        },
        {
          name: '_17001',
          value: -70,
          zoom: 0.65
        },
        {
          name: '_17002',
          value: 110,
          zoom: 0.4
        },
        {
          name: '_17003',
          value: -75,
          zoom: 0.75
        },
        {
          name: '_17004',
          value: 110,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'galaxy',
      frag: gallaxy,
      count: 7,
      countnull: 7,
      params: [
        {
          name: 'Scene Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#010105',
        },
      ],
      raycastRange : [
        {
          start: 53.6,
          end: 59.64
        }
      ],
      yAngle: [
        {
          name: '_18000',
          value: 103
        },
        {
          name: '_18001',
          value: 103
        },
        {
          name: '_18002',
          value: -76.9
        },
        {
          name: '_18003',
          value: -76.9
        },
        {
          name: '_18004',
          value: -76.9
        },
        {
          name: '_18005',
          value: 108
        },
        {
          name: '_18006',
          value: -76.9
        }
      ]
    },
    {
      name: 'Color Glitch',
      frag: noiseColor3,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Color Palette A',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#2b7c9b',
        },
        {
          name: 'Color Palette B',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#30d6ea',
        },
        {
          name: 'Color Palette D',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#421933',
        },
        {
          name: 'Global Amount',
          nameUniform: 'u_amount',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Global Ondulation',
          nameUniform: 'u_octaves',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 6,
        },
        {
          name: 'Wave A Pos X',
          nameUniform: 'u_utimeXA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.5075,
        },
        {
          name: 'Wave A Pos Y',
          nameUniform: 'u_utimeYA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.55,
        },
        {
          name: 'Wave A Scale',
          nameUniform: 'u_scaleA',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 2.0,
        },
        {
          name: 'Wave A Amount',
          nameUniform: 'u_numberA',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 9,
        },
        {
          name: 'Wave B Pos X',
          nameUniform: 'u_utimeXB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.5385,
        },
        {
          name: 'Wave B Pos Y',
          nameUniform: 'u_utimeYB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.5002,
        },
        {
          name: 'Wave B Scale',
          nameUniform: 'u_scaleB',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 8.0,
        },
        {
          name: 'Wave B Amount',
          nameUniform: 'u_numberB',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 3,
        },
        {
          name: 'Wave C Pos X',
          nameUniform: 'u_utimeXC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.57,
        },
        {
          name: 'Wave C Pos Y',
          nameUniform: 'u_utimeYC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.2455,
        },
        {
          name: 'Wave C Scale',
          nameUniform: 'u_scaleC',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 13.0,
        },
        {
          name: 'Wave C Size X',
          nameUniform: 'u_xC',
          min: 0,
          max: 10,
          decimal: 3,
          type: 'number',
          baseValue: 0.125,
        },
        {
          name: 'Wave C Size Y',
          nameUniform: 'u_yC',
          min: 0,
          max: 10,
          decimal: 3,
          type: 'number',
          baseValue: 0.5,
        },
        {
          name: 'Wave C Amount',
          nameUniform: 'u_numberC',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 4,
        }
      ],
      raycastRange : [
        {
          start: 53.6,
          end: 59.64
        }
      ],
      yAngle: [
        {
          name: '_19000',
          value: 105
        },
        {
          name: '_19001',
          value: -75.3
        },
        {
          name: '_19002',
          value: 105
        },
        {
          name: '_19003',
          value: 105
        }
      ]
    },
    {
      name: 'Rotating Polygon',
      frag: kale1,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 7,
        },
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 53.6,
          end: 59.64
        }
      ],
      yAngle: [
        {
          name: '_20000',
          value: -66.2
        },
        {
          name: '_20001',
          value: 111
        },
        {
          name: '_20002',
          value: 111
        },
        {
          name: '_20003',
          value: 111
        }
      ]
    },
    {
      name: 'Rotating',
      frag: frag1,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 61.42,
          end: 67.14
        }
      ],
      yAngle: [
        {
          name: '_21000',
          value: 35
        },
        {
          name: '_21001',
          value: -110
        },
        {
          name: '_21002',
          value: -110
        },
        {
          name: '_21003',
          value: 75
        },
        {
          name: '_21004',
          value: 75
        }
      ]
    },
    {
      name: 'Rotating 2',
      frag: kale3,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 61.42,
          end: 67.14
        }
      ],
      yAngle: [
        {
          name: '_22000',
          value: -110
        },
        {
          name: '_22001',
          value: 75
        },
        {
          name: '_22002',
          value: -110,
          zoom: 0.3
        },
        {
          name: '_22003',
          value: -110,
          zoom: 0.3
        },
        {
          name: '_22004',
          value: 75
        }
      ]
    },
    {
      name: 'Rotating 3',
      frag: kale4,
      count: 6,
      countnull: 6,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 61.42,
          end: 67.14
        }
      ],
      yAngle: [
        {
          name: '_23000',
          value: 75
        },
        {
          name: '_23001',
          value: -110
        },
        {
          name: '_23002',
          value: 75
        },
        {
          name: '_23003',
          value: -110
        },
        {
          name: '_23004',
          value: 125
        },
        {
          name: '_23005',
          value: -3
        }
      ]
    },
    {
      name: 'Water Color 2',
      frag: waterColor2,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Division',
          nameUniform: 'u_division',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 1,
        }
      ],
      raycastRange : [
        {
          start: 67.14,
          end: 69.17
        }
      ],
      yAngle: [
        {
          name: '_24000',
          value: -15
        },
        {
          name: '_24001',
          value: 165
        },
        {
          name: '_24002',
          value: -15
        },
        {
          name: '_24003',
          value: 165
        }
      ]
    },
    {
      name: 'Water Color 3',
      frag: waterColor3,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Division',
          nameUniform: 'u_division',
          min: 0,
          max: 750,
          type: 'number',
          baseValue: 125,
        },
        {
          name: 'Scale',
          nameUniform: 'u_scaleEl',
          min: 0,
          max: 15,
          decimal: 2,
          type: 'number',
          baseValue: 6.5,
        }
      ],
      raycastRange : [
        {
          start: 67.14,
          end: 69.17
        }
      ],
      yAngle: [
        {
          name: '_25000',
          value: 165
        },
        {
          name: '_25001',
          value: -15
        },
        {
          name: '_25002',
          value: 30
        },
        {
          name: '_25003',
          value: -32.6
        }
      ]
    },
    {
      name: 'Flower',
      frag: kale7,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 69.17,
          end: 72.04
        }
      ],
      yAngle: [
        {
          name: '_26000',
          value: 150
        },
        {
          name: '_26001',
          value: -27.3,
          zoom: 0.7
        },
        {
          name: '_26002',
          value: -27.3
        },
        {
          name: '_26003',
          value: -27.3,
          zoom: 0.7
        },
        {
          name: '_26004',
          value: 150
        }
      ]
    },
    {
      name: 'Indent Circle',
      frag: kale8,
      count: 5,
      countnull: 5,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        }
      ],
      raycastRange : [
        {
          start: 69.17,
          end: 72.04
        }
      ],
      yAngle: [
        {
          name: '_27000',
          value: 150,
          angle: 0.7
        },
        {
          name: '_27001',
          value: 23.2
        },
        {
          name: '_27002',
          value: -29.3
        },
        {
          name: '_27003',
          value: 155,
          zoom: 0.65
        },
        {
          name: '_27004',
          value: -25
        }
      ]
    },
    {
      name: 'Colorful Waves 2',
      frag: noiseColor2,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Color Palette A',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#f7689b',
        },
        {
          name: 'Color Palette B',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#fcd66b',
        },
        {
          name: 'Color Palette D',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#421933',
        },
        {
          name: 'Global Amount',
          nameUniform: 'u_amount',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Global Ondulation',
          nameUniform: 'u_octaves',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 6,
        },
        {
          name: 'Wave A Pos X',
          nameUniform: 'u_utimeXA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.5623,
        },
        {
          name: 'Wave A Pos Y',
          nameUniform: 'u_utimeYA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.4541,
        },
        {
          name: 'Wave A Scale',
          nameUniform: 'u_scaleA',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 5.2,
        },
        {
          name: 'Wave A Amount',
          nameUniform: 'u_numberA',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 9,
        },
        {
          name: 'Wave B Pos X',
          nameUniform: 'u_utimeXB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.0864,
        },
        {
          name: 'Wave B Pos Y',
          nameUniform: 'u_utimeYB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.1166,
        },
        {
          name: 'Wave B Scale',
          nameUniform: 'u_scaleB',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 3.97,
        },
        {
          name: 'Wave B Amount',
          nameUniform: 'u_numberB',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 0.97,
        },
        {
          name: 'Wave C Pos X',
          nameUniform: 'u_utimeXC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.3508,
        },
        {
          name: 'Wave C Pos Y',
          nameUniform: 'u_utimeYC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.5058,
        },
        {
          name: 'Wave C Scale',
          nameUniform: 'u_scaleC',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 5.79,
        },
        {
          name: 'Wave C Size X',
          nameUniform: 'u_xC',
          min: 0,
          max: 10,
          decimal: 3,
          type: 'number',
          baseValue: 3.321,
        },
        {
          name: 'Wave C Size Y',
          nameUniform: 'u_yC',
          min: 0,
          max: 10,
          decimal: 3,
          type: 'number',
          baseValue: 2.098,
        },
        {
          name: 'Wave C Amount',
          nameUniform: 'u_numberC',
          min: 0,
          max: 12,
          decimal: 2,
          type: 'number',
          baseValue: 1.7,
        }
      ],
      raycastRange : [
        {
          start: 72.04,
          end: 73.5
        }
      ],
      yAngle: [
        {
          name: '_28000',
          value: -25,
          zoom: 0.95
        },
        {
          name: '_28001',
          value: 152.5,
          zoom: 0.75
        },
        {
          name: '_28002',
          value: 152.5,
          zoom: 0.75
        },
        {
          name: '_28003',
          value: 152.5,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Light Circle',
      frag: circleLight,
      count: 6,
      countnull: 6,
      params: [
        {
          name: 'Number of dots',
          nameUniform: 'u_dots',
          min: 0,
          max: 30,
          type: 'number',
          baseValue: 19,
        },
        {
          name: 'Amount of Rotation',
          nameUniform: 'u_turn',
          min: 0,
          max: 5,
          type: 'number',
          baseValue: 0,
        },
      ],
      raycastRange : [
        {
          start: 72.5,
          end: 75.9
        }
      ],
      yAngle: [
        {
          name: '_29000',
          value: -25,
          zoom: 0.85
        },
        {
          name: '_29001',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_29002',
          value: 110,
          zoom: 0.85
        },
        {
          name: '_29003',
          value: 110,
          zoom: 0.85
        },
        {
          name: '_29004',
          value: -25,
          zoom: 0.75
        },
        {
          name: '_29005',
          value: -100,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Rotation 4',
      frag: kale2,
      count: 6,
      countnull: 6,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 7,
        },
      ],
      raycastRange : [
        {
          start: 75.9,
          end: 79.68
        }
      ],
      yAngle: [
        {
          name: '_30000',
          value: -25
        },
        {
          name: '_30001',
          value: 150,
          zoom: 0.75
        },
        {
          name: '_30002',
          value: -25,
          zoom: 0.75
        },
        {
          name: '_30003',
          value: 150,
          zoom: 0.85
        },
        {
          name: '_30004',
          value: -60.6
        },
        {
          name: '_30005',
          value: 75
        }
      ]
    },
    {
      name: 'Rotation 5',
      frag: kale9,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 8,
        },
      ],
      raycastRange : [
        {
          start: 75.9,
          end: 79.68
        }
      ],
      yAngle: [
        {
          name: '_31000',
          value: -25,
          zoom: 0.75
        },
        {
          name: '_31001',
          value: 70,
          zoom: 0.35
        },
        {
          name: '_31002',
          value: 150,
          zoom: 0.85
        },
        {
          name: '_31003',
          value: 150,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Rotation 6',
      frag: kale10,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.875,
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 3,
        },
      ],
      raycastRange : [
        {
          start: 75.9,
          end: 79.68
        }
      ],
      yAngle: [
        {
          name: '_32000',
          value: 150,
          zoom: 0.75
        },
        {
          name: '_32001',
          value: -25,
          zoom: 0.75
        },
        {
          name: '_32002',
          value: 140,
          zoom: 0.65
        },
        {
          name: '_32003',
          value: -25,
          zoom: 0.75
        }
      ]
    },
    {
      name: 'Optical Square',
      frag: optical,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#f7689b',
        },
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 2,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
      ],
      raycastRange : [
        {
          start: 81.9,
          end: 86.77
        }
      ],
      yAngle: [
        {
          name: '_33001',
          value: 15,
          zoom: 0.8
        },
        {
          name: '_33000',
          value: 15,
          zoom: 0.8
        },
        {
          name: '_33002',
          value: 15,
          zoom: 0.8
        }
      ]
    },
    {
      name: 'Rotating Square',
      frag: square,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Background Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#000000',
        },
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
        {
          name: 'Black Offset',
          nameUniform: 'u_number',
          min: 0,
          max: 100,
          type: 'number',
          baseValue: 12,
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_sides',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 4,
        },
      ],
      raycastRange : [
        {
          start: 81.9,
          end: 86.77
        }
      ],
      yAngle: [
        {
          name: '_34000',
          value: 22,
          zoom: 0.95
        },
        {
          name: '_34001',
          value: 22,
          zoom: 0.95
        },
        {
          name: '_34002',
          value: 22,
          zoom: 0.95
        }
      ]
    },
    {
      name: 'Moving Grid',
      frag: vasarely1,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
      ],
      raycastRange : [
        {
          start: 81.9,
          end: 86.77
        }
      ],
      yAngle: [
        {
          name: '_35000',
          value: 18.1,
          zoom: 0.9
        },
        {
          name: '_35001',
          value: 18.1,
          zoom: 0.9
        },
        {
          name: '_35002',
          value: 18.1,
          zoom: 0.9
        },
        {
          name: '_35003',
          value: 18.1,
          zoom: 0.9
        }
      ]
    },
    {
      name: 'Dot Wave',
      frag: dot,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Size',
          nameUniform: 'u_size',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 2.5,
        },
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
      ],
      raycastRange : [
        {
          start: 81.9,
          end: 86.77
        }
      ],
      yAngle: [
        {
          name: '_36000',
          value: 18.1,
          zoom: 0.95
        },
        {
          name: '_36001',
          value: 18.1,
          zoom: 0.95
        },
        {
          name: '_36002',
          value: 18.1,
          zoom: 0.95
        }
      ]
    },
    {
      name: 'Triangle Tunnel',
      frag: lines,
      count: 6,
      countnull: 6,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
        {
          name: 'Number of Triangles',
          nameUniform: 'u_segmentation',
          min: 0,
          max: 200,
          type: 'number',
          baseValue: 40,
        },
      ],
      raycastRange : [
        {
          start: 87.2,
          end: 91
        }
      ],
      yAngle: [
        {
          name: '_37000',
          value: 100
        },
        {
          name: '_37001',
          value: 100
        },
        {
          name: '_37002',
          value: -27.7,
          zoom: 0.75
        },
        {
          name: '_37003',
          value: -130,
          zoom: 0.75
        },
        {
          name: '_37004',
          value: -75,
          zoom: 0.75
        },
        {
          name: '_37005',
          value: -75,
          zoom: 0.85
        }
      ]
    },
    {
      name: 'Rotating Lines',
      frag: lines2,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Speed',
          nameUniform: 'u_speed',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.825,
        },
        {
          name: 'Number of Lines',
          nameUniform: 'u_segmentation',
          min: 0,
          max: 80,
          type: 'number',
          baseValue: 40,
        },
      ],
      raycastRange : [
        {
          start: 91.35,
          end: 96
        }
      ],
      yAngle: [
        {
          name: '_38000',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_38001',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_38002',
          value: 20,
          zoom: 0.85
        }
      ]
    },
    {
      name: 'Rotating Polygon',
      frag: poly4,
      count: 3,
      countnull: 3,
      params: [
        {
          name: 'Overlay Color',
          nameUniform: 'u_color',
          type: 'color',
          baseValue: '#e76fd0',
        },
        {
          name: 'Background Color',
          nameUniform: 'u_colorSecond',
          type: 'color',
          baseValue: '#530595',
        },
        {
          name: 'Number Sides',
          nameUniform: 'u_number',
          min: 0,
          max: 12,
          type: 'number',
          baseValue: 8,
        },
      ],
      raycastRange : [
        {
          start: 91.35,
          end: 96
        }
      ],
      yAngle: [
        {
          name: '_39000',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_39001',
          value: 20,
          zoom: 0.95
        },
        {
          name: '_39002',
          value: 20,
          zoom: 0.95
        }
      ]
    },
    {
      name: 'Colorful Waves',
      frag: noiseColor,
      count: 4,
      countnull: 4,
      params: [
        {
          name: 'Color Palette A',
          nameUniform: 'u_colorA',
          type: 'color',
          baseValue: '#20df6c',
        },
        {
          name: 'Color Palette B',
          nameUniform: 'u_colorB',
          type: 'color',
          baseValue: '#2bde16',
        },
        {
          name: 'Color Palette D',
          nameUniform: 'u_colorD',
          type: 'color',
          baseValue: '#3a1dc7',
        },
        {
          name: 'Global Amount',
          nameUniform: 'u_amount',
          min: 0,
          max: 5,
          decimal: 2,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Global Ondulation',
          nameUniform: 'u_octaves',
          min: 0,
          max: 10,
          type: 'number',
          baseValue: 2,
        },
        {
          name: 'Wave A Pos X',
          nameUniform: 'u_utimeXA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.0843,
        },
        {
          name: 'Wave A Pos Y',
          nameUniform: 'u_utimeYA',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.8682,
        },
        {
          name: 'Wave A Scale',
          nameUniform: 'u_scaleA',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 2.29,
        },
        {
          name: 'Wave A Amount',
          nameUniform: 'u_numberA',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 0.93,
        },
        {
          name: 'Wave B Pos X',
          nameUniform: 'u_utimeXB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.2901,
        },
        {
          name: 'Wave B Pos Y',
          nameUniform: 'u_utimeYB',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.6955,
        },
        {
          name: 'Wave B Scale',
          nameUniform: 'u_scaleB',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 4.28,
        },
        {
          name: 'Wave B Amount',
          nameUniform: 'u_numberB',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1.33,
        },
        {
          name: 'Wave C Pos X',
          nameUniform: 'u_utimeXC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 0.3449,
        },
        {
          name: 'Wave C Pos Y',
          nameUniform: 'u_utimeYC',
          min: 0,
          max: 3,
          decimal: 4,
          type: 'number',
          baseValue: 1.0243,
        },
        {
          name: 'Wave C Scale',
          nameUniform: 'u_scaleC',
          min: 0,
          max: 20,
          decimal: 2,
          type: 'number',
          baseValue: 6.2,
        },
        {
          name: 'Wave C Size X',
          nameUniform: 'u_xC',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 4.92,
        },
        {
          name: 'Wave C Size Y',
          nameUniform: 'u_yC',
          min: 0,
          max: 10,
          decimal: 2,
          type: 'number',
          baseValue: 5.7,
        },
        {
          name: 'Wave C Amount',
          nameUniform: 'u_numberC',
          min: 0,
          max: 3,
          decimal: 2,
          type: 'number',
          baseValue: 1.26,
        }
      ],
      raycastRange : [
        {
          start: 91.35,
          end: 96
        }
      ],
      yAngle: [
        {
          name: '_40000',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_40001',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_40002',
          value: 20,
          zoom: 0.85
        },
        {
          name: '_40003',
          value: 20,
          zoom: 0.85
        }
      ]
    }    
  ]
}

export default configScreen