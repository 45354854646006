import ScrollManager from '../manager/ScrollManager'
import {
  Color,
  DoubleSide,
  MeshBasicMaterial,
  MeshStandardMaterial,
  ShaderMaterial,
  Vector2,
  Vector3,
  EdgesGeometry,
  LineSegments,
  LineBasicMaterial,
  Raycaster,
  Quaternion,
  MathUtils,
  FrontSide,
  BackSide,
  Group,
  Matrix4, 
  Box3
} from 'three';
import WebGLManager from '../manager/WebGLManager'
import gsap from 'gsap';
import vertex from '../../shader/fragment/vertex.glsl?raw'
import { Vector4 } from 'three';
export default class Screen {
  constructor({elems, nulls, frag, count, name, raycastRange, yAngle, params}) {

    this.elems = elems;
    this.nulls = nulls;
    this.frag = frag;
    this.count = count;
    this.name = name;
    this.yAngle = yAngle;
    this.params = params
    this.raycastRange = raycastRange;
    this.elClicked = [];
    this.nullObject = [];
    this.numberParams = [];
    this.colorParams = [];

    this.condition = false;

    this.raycaster = new Raycaster();
		this.pointer = new Vector2();
		this.onUpPosition = new Vector2();
		this.onDownPosition = new Vector2();
    this.currentIntersect = null

    this.initParams();

    this.time = Math.random() * 100.0;

    this.uniforms = {
      u_time: { type: "f", value: this.time },
      u_resolution: { type: "v2", value: new Vector2() },
      u_scale: { type: "v2", value: new Vector2() },
      u_number: { value: 8 },
    };

    this.updateUniforms();

    this.material = new ShaderMaterial( {
      uniforms: this.uniforms,
      vertexShader: vertex,
      fragmentShader: this.frag
    } );

    this.materialClicked = new MeshBasicMaterial( {
      color: new Color(0x00ff00)
    } );

    this.bindMethods();
    
    this.isTouch = window.matchMedia('(pointer: coarse)').matches;

    this.events();

    this.init(this.elems);
  }

  events() {
    if(this.isTouch) {
      document.addEventListener('click', this.onPointerMove);
    } else {
      document.addEventListener('pointermove', this.onPointerMove);
      document.addEventListener('pointerdown', this.onPointerDown);
    }
  }

  bindMethods() {
    this.onPointerDown = this.onPointerDown.bind(this)
    this.onPointerMove = this.onPointerMove.bind(this)
  }

  init(elems) {
    this.times = [];
    if(this.nulls.length > 0) {
      this.nulls.forEach((element) => {
        this.nullObject.push(element);
      });
    }
    

    this.elems.forEach((element, index) => {
      if(this.nullObject.length > 0) {
        const nameCheck = element.name.replace('Screen', '');
        this.nullObject.forEach((nullObj, index) => {
          if(nullObj.name.includes(nameCheck)) {
            element.nullObj = nullObj
            for(const angle of this.yAngle) {
              if(angle.name.includes(nameCheck)) {
                element.nullObj.nullObjRotationY = angle.value
                element.nullObj.zoom = (angle.zoom) ? angle.zoom : 0.5;
              }
            }
          }
        });
      }

      this.elClicked.push(element);
      

      if(this.frag) {
        element.material = this.material.clone();

        this.times.push(element.material.uniforms.u_time)

        if(element.name === 'Screen_0000' && element.name === 'Screen_3001' && element.name === 'Screen_3002' && element.name === 'Screen_3003') element.material.side = FrontSide
        else element.material.side = DoubleSide

        if(element.name === 'Screen_12000') element.material.side = BackSide

        element.frustumCulled = true;
        element.material.needsUpdate = true;
        const width = Number((element.geometry.boundingBox.max.x * 2).toFixed(5))
        const height = Number((element.geometry.boundingBox.max.z * 2).toFixed(5))

        element.material.uniforms.u_resolution.value.x = width;
        element.material.uniforms.u_resolution.value.y = height;

        element.material.uniforms.u_scale.value.x = element.scale.x
        element.material.uniforms.u_scale.value.y = element.scale.y

        element.material.needsUpdate = true;
      }
    });
  }

  initParams() {
    if (!this.params) return;

    this.params.forEach((element) => {
      if(element.type === 'color') this.colorParams.push(element);
      else this.numberParams.push(element);
    });
  }

  updateUniforms() {
    if (!this.params) return;

    this.colorParams.forEach((element) => {
      const nameUniform = element.nameUniform;
      const newValue = parseInt(element.baseValue.replace('#', '0x'));

      this.uniforms[nameUniform] = { value: null };

      this.uniforms[nameUniform].value = new Color(newValue)
    });

    this.numberParams.forEach((element) => {
      const nameUniform = element.nameUniform;

      this.decimalNumber = element.decimal ? element.decimal : 0;

      const newValue = element.baseValue.toFixed(this.decimalNumber);

      this.uniforms[nameUniform] = { value: null };

      this.uniforms[nameUniform].value = Number(newValue)
    });
  }


  onPointerMove(event) {
    if(this.isTouch) {
      this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

      this.condition =  this.raycastRange[1] ? WebGLManager.cameraProgress > 0 && (WebGLManager.cameraProgress >= this.raycastRange[0].start && WebGLManager.cameraProgress <= this.raycastRange[0].end) || WebGLManager.cameraProgress > 0 && (WebGLManager.cameraProgress >= this.raycastRange[1].start && WebGLManager.cameraProgress <= this.raycastRange[1].end) : (WebGLManager.cameraProgress >= this.raycastRange[0].start && WebGLManager.cameraProgress <= this.raycastRange[0].end)
      if(!this.condition) return
  
      this.raycaster.setFromCamera(this.pointer, WebGLManager.instance.camera)
      const intersects = this.raycaster.intersectObjects(this.elClicked)
      if(intersects.length > 0)
      {
        if(intersects[0] && intersects[0].distance <= 35) this.currentIntersect = intersects[0];
      } else {
        this.currentIntersect = null
        return
      }

      if(this.currentIntersect) {
        this.onPointerDown();
      } else {
        this.currentIntersect = null
      }
    } else {
      this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
      this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    }
  }

  onPointerDown() {
    if(WebGLManager.isClicked) return;
    
    if(this.currentIntersect) {
      ScrollManager.lenis.stop();
      ScrollManager.lenis.wrapperNode.classList.add('pt-none')
      const object = this.currentIntersect.object;
      if(!object.nullObj) return;
      ScrollManager.lenis.stop();

      WebGLManager.isClicked = true;
      WebGLManager.isAnimating = true;

      WebGLManager.params.getInfo(this.colorParams, this.numberParams, object.material.uniforms, this.name);

      WebGLManager.instance.camera.oldPosition = new Vector3(WebGLManager.instance.camera.position.x, WebGLManager.instance.camera.position.y, WebGLManager.instance.camera.position.z)
      WebGLManager.instance.camera.oldRotation = new Vector3(WebGLManager.instance.camera.rotation.x, WebGLManager.instance.camera.rotation.y, WebGLManager.instance.camera.rotation.z)

      const pPlane = new Vector3();

      object.nullObj.updateWorldMatrix();
      object.nullObj.getWorldPosition(pPlane)
      this.animateCameraPlane = gsap.timeline({
        onUpdate: () => {
          WebGLManager.instance.camera.zoom = zoom.value;
          WebGLManager.instance.camera.updateProjectionMatrix();
        },
        onComplete: () => {
          WebGLManager.zoomElement = zoom.value
        }
      })

      const zoom = { value : 1 }

      this.animateCameraPlane.to(WebGLManager.instance.camera.position, {
        x: pPlane.x,
        y: pPlane.y,
        z: pPlane.z,
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.to(WebGLManager.instance.camera.rotation, {
        y: MathUtils.degToRad(object.nullObj.nullObjRotationY),
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.to(zoom, {
        value: object.nullObj.zoom,
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.to(WebGLManager.rotatingSphere, {
        yPercent: 120,
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.to(WebGLManager.socialsEl, {
        opacity: 0,
        pointerEvents: 'none',
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.to(WebGLManager.params.el, {
        xPercent: 0,
        ease: 'power2.inOut',
        duration: 1.5
      }, 'start')

      this.animateCameraPlane.add(WebGLManager.params.show(), 'start+=1.0');
    }
  }

  update() {
    if (!this.time) return
    this.time += 0.025;    

    this.times.forEach((element) => {
      element.value = this.time
    })
    

    if(this.isTouch) return;

    this.condition =  this.raycastRange[1] ? WebGLManager.cameraProgress > 0 && (WebGLManager.cameraProgress >= this.raycastRange[0].start && WebGLManager.cameraProgress <= this.raycastRange[0].end) || WebGLManager.cameraProgress > 0 && (WebGLManager.cameraProgress >= this.raycastRange[1].start && WebGLManager.cameraProgress <= this.raycastRange[1].end) : (WebGLManager.cameraProgress >= this.raycastRange[0].start && WebGLManager.cameraProgress <= this.raycastRange[0].end)
    this.currentIntersect = null
    if(!this.condition) return

    this.raycaster.setFromCamera(this.pointer, WebGLManager.instance.camera)
    const intersects = this.raycaster.intersectObjects(this.elClicked)
    if(intersects.length > 0)
    {
      if(intersects[0] && intersects[0].distance <= 35) this.currentIntersect = intersects[0];
    } else {
      this.currentIntersect = null
      return
    }
  }

  resize() {
    this.isTouch = window.matchMedia('(pointer: coarse)').matches;
    
    for (const elem of this.elClicked) {
      const width = Number((elem.geometry.boundingBox.max.x * 2).toFixed(5))
      const height = Number((elem.geometry.boundingBox.max.z * 2).toFixed(5))

      elem.material.uniforms.u_resolution.value.x = width;
      elem.material.uniforms.u_resolution.value.y = height;

      elem.material.uniforms.u_scale.value.x = elem.scale.x
      elem.material.uniforms.u_scale.value.y = elem.scale.y

      elem.material.needsUpdate = true;
    }
  }
}