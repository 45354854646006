import {
  Color
} from 'three';
import WebGLManager from '../manager/WebGLManager'
import InputRange from './InputRange.js'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import Lenis from '@studio-freight/lenis'
gsap.registerPlugin(Draggable)
export default class Params {
  constructor({el}) {
    this.el = el;

    this.init()
  }

  init() {
    this.getElems();
    this.bindMethods();

    this.initScroll()
  }

  getElems() {
    this.paramsName = this.el.querySelector('.js-params-name')
    this.paramsContent = this.el.querySelector('.js-params-content')
    this.rangeDefault = this.el.querySelector('.input-range')

    this.divScroll = this.el.querySelector('.js-scrollable-content')
    this.divScrollInner = this.el.querySelector('.js-scrollable-content-inner')
  }

  bindMethods() {
    this.createDraggable = this.createDraggable.bind(this)
    this.updateColor = this.updateColor.bind(this)
    this.render = this.render.bind(this)
  }

  initScroll() {
    this.oScroll = new Lenis({
      wrapper: this.divScroll, content: this.divScrollInner, duration: 2.4
    })
    this.opacityElems = this.el.querySelectorAll('.js-anim-params');
  }

  valueToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }  

  rgbToHex(r, g, b) {
    return "#" + this.valueToHex(r) + this.valueToHex(g) + this.valueToHex(b);
  }

  getInfo(colors, numbers, uniforms, name) {
    this.inputColors = [];
    this.inputNumbers = [];

    this.paramsName.textContent = name
    this.elUniforms = uniforms;

    colors.forEach((element) => {
      this.createColors(element, uniforms)
    });

    numbers.forEach((element) => {
      this.createNumbers(element, uniforms)
    });
  }

  createColors(element, uniform) {
    const name = element.nameUniform;

    const parentElement = document.createElement('div');
    const colorValue = document.createElement('span');
    parentElement.classList.add('js-params-colors', 'colors');
    colorValue.classList.add('colors-value');

    const r = uniform[name].value.r * 255;
    const g = uniform[name].value.g * 255;
    const b = uniform[name].value.b * 255;

    const inputColor = document.createElement('input');
    inputColor.setAttribute('type', 'color');
    inputColor.setAttribute('defaultValue', element.baseValue);
    inputColor.setAttribute('value', this.rgbToHex(r, g, b));
    inputColor.setAttribute('name', element.nameUniform);

    parentElement.style.setProperty('--colorInput', this.rgbToHex(r, g, b));

    colorValue.textContent = this.rgbToHex(r, g, b);

    this.inputColors.push(inputColor);

    parentElement.append(inputColor);
    parentElement.append(colorValue);

    this.createParent(parentElement, element.name);
  }

  createNumbers(element, uniform) {
    const name = element.nameUniform;
    const numberElement = document.createElement('span');
    numberElement.textContent = uniform[name].value

    const divParentNumber = document.createElement('div');
    divParentNumber.classList.add('filter-input', 'input-range', 'w-full', 'bg-white', 'bg-opacity-20', 'mt-5')

    divParentNumber.dataset.max = element.max;
    divParentNumber.dataset.min = element.min;
    divParentNumber.dataset.valueInit = uniform[name].value;
    divParentNumber.dataset.decimal = element.decimal;
    divParentNumber.dataset.nameUniform = element.nameUniform;

    const inputHidden = document.createElement('input');
    inputHidden.setAttribute('type', 'hidden');
    inputHidden.setAttribute('value', uniform[name].value);

    const divParentNumberRange = document.createElement('div');
    divParentNumberRange.classList.add('input-range__track',  'yst-pointer-events-none', 'h-full', 'w-full')

    const divParentNumberThumb = document.createElement('div');
    divParentNumberThumb.classList.add('input-range__thumb', 'absolute', 'cursor-pointer')

    const divParentNumberCursor = document.createElement('div');
    divParentNumberCursor.classList.add('input-range__cursor', 'absolute')

    const divParentNumberValue = document.createElement('div');
    divParentNumberValue.classList.add('input-range__value', 'block', 'absolute', 'top-full', 'text-center')

    divParentNumberValue.textContent = 0

    divParentNumberThumb.append(divParentNumberCursor)
    divParentNumberThumb.append(divParentNumberValue)

    divParentNumber.append(divParentNumberRange)
    divParentNumber.append(divParentNumberThumb)
    divParentNumber.append(inputHidden)

    this.inputNumbers.push(divParentNumber);
    this.createParent(divParentNumber, element.name);
  }

  createParent(element, name) {
    const paragraph = document.createElement('p')
    const parentElement = document.createElement('div');
    parentElement.classList.add('js-params-parent', 'filter');

    const spanLine = document.createElement('span');
    spanLine.classList.add('js-line-params', 'line-params');

    paragraph.classList.add('js-anim-params', 'anim-params');
    element.classList.add('js-anim-params', 'anim-params');
    
    paragraph.textContent = name;
    parentElement.append(paragraph);
    parentElement.append(element);
    parentElement.append(spanLine)
    this.divScrollInner.append(parentElement)

    this.opacityElems = this.el.querySelectorAll('.js-anim-params');
    this.lineElems = this.el.querySelectorAll('.js-line-params');

    this.createEvents()
  }

  createEvents() {
    this.draggbleElements = []

    this.inputNumbers.forEach((element) => {
      this.createDraggable(element)
    });

    this.inputColors.forEach((element) => {
      element.addEventListener('input', this.updateColor)
    });
  }

  createDraggable(element) {
    const draggable = new InputRange({el: element, uniforms: this.elUniforms})

    this.draggbleElements.push(this.draggable)
    
  }

  show() {
    const value = window.innerHeight - (this.paramsName.offsetHeight + this.paramsName.offsetTop)

    document.body.style.setProperty('--height-scrollable', value + 'px');

    const tl = gsap.timeline({
      onComplete: () => {
        WebGLManager.isAnimating = false;
      }
    })
    tl.to(this.opacityElems, {
      opacity: 1,
      stagger: 0.1,
      ease: 'power2.inOut',
      duration: 0.5
    }, 'start')

    tl.to(this.lineElems, {
      scaleX: 1,
      stagger: 0.1,
      ease: 'power2.inOut',
      duration: 0.5
    }, 'start')

    return tl;
  }

  hide() {
    const tl = gsap.timeline()
    tl.to(this.opacityElems, {
      opacity: 0,
      duration: 0.25,
      ease: 'power2.inOut',
      stagger: {
        each: 0.1,
        from: 'end'
      }
    }, 'start')

    tl.to(this.lineElems, {
      scaleX: 0,
      duration: 0.25,
      ease: 'power2.inOut',
      stagger: {
        each: 0.1,
        from: 'end'
      }
    }, 'start')

    return tl;
  }

  updateColor(e) {
    const spanColor = e.target.parentElement.querySelector('.colors-value');

    e.target.parentElement.style.setProperty('--colorInput', '' + e.target.value + '');

    const newValue = parseInt(e.target.value.replace('#', '0x'));
    spanColor.textContent = e.target.value;
    this.elUniforms[e.target.name].value = new Color(newValue)
  }

  render(time) {
    if (this.oScroll) this.oScroll.raf(time)
  }

  resize() {
    this.draggbleElements.forEach((element) => {
      element.resize();
    });
  }

  destroy() {
    this.draggbleElements.forEach((element) => {
      element.destroy();
    });
  }
}