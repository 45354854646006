import {
  Scene,
  Cache,
  WebGLRenderer,
  PerspectiveCamera,
  CameraHelper,
  DirectionalLight,
  DirectionalLightHelper,
  sRGBEncoding,
  Group,
  AmbientLight,
  Vector2,
  ShaderMaterial,
  SpotLight
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

/**
 * A class used to initialize everything needed to start a WebGL context across the app.
 *
 * @param {Object} props - An object containing parameters properties.
 * @param {Boolean} props.debug - A boolean defining whether the webgl should be in debug mode or not (activating gui, OrbitControls and some other helpers).
 * @param {Object} props.rendererOptions - An object with all the options that will be passed to the `WebGLRenderer`.
 *
 * @class
 */
export default class WebGLManager {
  constructor(container, sizes, tier) {
    this.container = container;
    this.sizes = sizes;
    this.tier = tier;
    this.isRendering = false
    this.isReady = false
    this.isActive = false
    this.isVisible = false
    this.currentPageGL = null
    this.onIntersect = this.onIntersect.bind(this)
    this.disabledOrbit = false;

    this.isAnimating = false

    // this.tier = 0;

    WebGLManager.instance = this
    WebGLManager.isOrbit = false

    WebGLManager.cameraProgress = 0

    WebGLManager.params = null;
    this.leavePosition = null;
    this.leaveQuaternion = null;

    WebGLManager.leavePosition = this.leavePosition;
    WebGLManager.leaveQuaternion =  this.leaveQuaternion;

    Cache.enabled = true

    this.createRenderer()
    this.createScene()
    this.createCamera()
    this.createLights()

    this.currentCamera = this.camera
    WebGLManager.camera = this.currentCamera
    this.initBloom();
  }

  /**
   * Creates the `THREE.WebGLRenderer`.
   *
   * Adds the canvas to the document and watches it's visiblity.
   *
   * @param {Object} options An object that will be passed to the `WebGLRenderer` constructor.
   *
   * @returns {void}
   */
  createRenderer() {
    this.renderer = new WebGLRenderer({
      canvas: this.container,
      powerPreference: 'high-performance',
      alpha: false,
      antialias: this.tier // false on bad device
    })

    // this.renderer.setClearColor(0xffffff)
    // this.renderer.physicallyCorrectLights = true
    // this.renderer.outputEncoding = sRGBEncoding
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    this.renderer.domElement.classList.add('hidden', 'z-0', 'fixed', 'inset-0', 'pointer-events-none')

    document.querySelector('.js-webgl').prepend(window.body)

    this.observer = new IntersectionObserver(this.onIntersect, {
      rootMargin: '0px',
      threshold: 0
    })

    this.observer.observe(this.renderer.domElement)
  }

  /**
   * Called whenever the canvas goes inside or outside of the viewport.
   *
   * Changes the value `this.isVisible` accordingly.
   *
   * @param {Array<IntersectionObserverEntry>} entries - The related entries.
   * @param {String} observer - The IntersectionObserver instance.
   *
   * @returns {void}
   */
  onIntersect(entries, observer) {
    if (!entries.length) return

    this.isVisible = entries[0].isIntersecting
  }

  /**
   * Creates a new `THREE.Scene`.
   *
   * @returns {void}
   */
  createScene() {
    this.scene = new Scene()
  }

  /**
   * Creates a new `THREE.PerspectiveCamera` and adds it to the Scene.
   *
   * @returns {void}
   */
  createCamera() {
    this.camera = new PerspectiveCamera(65, this.sizes.width / this.sizes.height, 0.1, 100000)

    // this.controlsMain = new OrbitControls(this.camera, this.container) // A Commenter APRES RETRAVAILLE DE LA SPLINE

    this.cameraHelper = new CameraHelper(this.camera)

    this.cameraHelper.visible = false

    this.camera.position.z = 17
    WebGLManager.unitSize = WebGLManager.getUnitSize()

    this.groupCamera = new Group();
    WebGLManager.groupCamera = this.groupCamera;

    this.groupCamera.add(this.camera);
    this.groupCamera.add(this.cameraHelper);

    this.scene.add(this.groupCamera)
    // this.scene.add(this.cameraHelper)
  }

  static getUnitSize(depth = 0) {
    const cam = WebGLManager.getCamera()
    const offset = cam.position.z
    let d = depth

    if (depth < offset) d -= offset
    else d += offset

    const vFOV = (cam.fov * Math.PI) / 180 // vertical fov in radians

    // Math.abs to ensure the result is always positive
    const height = 2 * Math.tan(vFOV / 2) * Math.abs(d)
    const width = height * cam.aspect

    return {
      height,
      width
    }
  }

  static getViewSizeAtDepth(depth = 0) {
    const cam = WebGLManager.getCamera()
    const fovInRadians = (cam.fov * Math.PI) / 180;
    const height = Math.abs(
      (cam.position.z - depth) * Math.tan(fovInRadians / 2) * 2
    );
    return { width: height * cam.aspect, height };
  }

  /**
   * Creates an object with some lights and adds them to the Scene.
   *
   * @returns {void}
   */
  createLights() {
    this.lights = {
      directional: new DirectionalLight(0xffffff, 1)
    }

    // scene.add( spotLight );

    // const ambientLight = new AmbientLight( 0x524200, 1.75 );
    const ambientLight = new AmbientLight( 0xD9D9D9, 1.25 );

    this.scene.add(ambientLight)
  }

    /**
   * Sets a PageGL instance as the new `this.currentPageGL`.
   *
   * @param {PageGL} pageGL - A PageGL instance which will be set as the new `currentPageGL`.
   *
   * @static
   * @returns {void}
   */
    static setCurrentPage(pageGL) {
      if (!pageGL) return
  
      WebGLManager.instance.currentPageGL = pageGL
    }
  
  /**
   * Gets the current pageGL.
   *
   * @static
   * @returns {(PageGL|null)} The value of `this.currentPageGL`.
   */
    static getCurrentPage() {
      return WebGLManager.instance.currentPageGL
    }

  /**
   * A method to call when the WebGL context is created and ready to run.
   *
   * Sets `this.isReady = true`.
   *
   * Immediately forces a render of the scene with the active Camera.
   *
   * Called by `App` in its `start()` method.
   *
   * @returns {void}
   */
  ready() {
    this.isReady = true

    this.renderer.render(this.scene, this.currentCamera)
  }

  /**
 * Disable Controls of the camera.
 *
 * @static
 * @returns {void}
 */
  static disabledControl() {
    if (this.instance.controlsMain) {
      this.instance.controlsMain.enabled = false;
    } 
  }

  /**
   * Enable Controls of the camera.
   *
   * @static
   * @returns {void}
   */
  static enabledControl() {
    if (this.instance.controlsMain) this.instance.controlsMain.enabled = true;
  }
  



  /**
   * Adds objects to the scene.
   *
   * @param  {...Object3D} objects The Object3D instances you want to add to the scene.
   *
   * @static
   * @returns {void}
   */
  static addToScene(...objects) {
    if (objects) WebGLManager.instance.scene.add(...objects)
  }

  /**
   * Adds an object to the Camera.
   *
   * @param {...Object3D} objects The Object3D instances to add to the Camera.
   *
   * @static
   * @returns {void}
   */
  static addToCamera(...objects) {
    if (objects) WebGLManager.instance.camera.add(...objects)
  }

  /**
   * Removes objects from the scene.
   *
   * @param {(Object3D|Array<Object3D>)} objects The Object3D that will be removed from the scene.
   *
   * @static
   * @returns {void}
   */
  static removeFromScene(objects) {
    if (Array.isArray(objects)) {
      for (const obj of objects) {
        WebGLManager.removeObject(obj)
      }
    } else WebGLManager.removeObject(objects)
  }

  /**
   * Removes a single object from the scene.
   *
   * @param {Object3D} obj The Object3D that will be removed from the scene.
   *
   * @static
   * @returns {void}
   */
  static removeObject(obj) {
    if (!obj) return

    WebGLManager.instance.scene.remove(obj)

    obj.traverse((child) => {
      if (child.geometry) child.geometry.dispose()
      if (child.material) child.material.dispose()
    })
  }

    /**
   * Gets the canvas DOM element.
   *
   * @static
   * @returns {Element} The canvas.
   */
    static getCanvas() {
      return WebGLManager.instance.renderer.domElement
    }

  /**
   * Gets the main Camera.
   *
   * @static
   * @returns {Camera} The Camera.
   */
  static getCamera() {
    return WebGLManager.instance.camera
  }

  /**
   * Forces a new render of the Scene using the current Camera.
   *
   * @static
   * @returns {void}
   */
  static render() {
    WebGLManager.instance.renderer.render(WebGLManager.instance.scene, WebGLManager.instance.currentCamera)
  }

  /**
   * Returns the instance's `isRendering` property.
   *
   * @returns {Boolean} Whether the context is rendering or not.
   */
  static isRendering() {
    return WebGLManager.instance.isRendering
  }

  initBloom() {
    const renderScene = new RenderPass(WebGLManager.instance.scene, WebGLManager.instance.camera);
    this.composer = new EffectComposer(WebGLManager.instance.renderer);
    WebGLManager.composer = this.composer;
    WebGLManager.renderScene = renderScene;
    this.composer.addPass(renderScene);
  }

  /**
   * Renders a new frame only if **ALL** of the following conditions are **true** :
   *
   * - `this.isReady === true` - The context is ready.
   * - `this.isActive === true` - The context is active.
   * - `this.isVisible === true` - The canvas is visible on the viewport.
   * - `this.currentPageGL !== null` - A PageGL instance is exists.
   * - `this.currentPageGL.disabled === false` - The PageGL instance is not disabled.
   *
   * **THEN** it will call `this.currentPageGL.update()` and render the frame with the current Camera.
   *
   * @param {Object} params - An object containing two properties related to the elapsed time.
   * @param {Number} params.time - The elapsed time.
   * @param {Number} params.deltaTime - The difference of time between the previous frame and the current.
   *
   * @returns {void}
   */
  render({ time, deltaTime }) {
    // if (!this.isReady || !this.isActive || !this.isVisible || !this.currentPageGL || (this.currentPageGL && this.currentPageGL.disabled)) {
    //   if (this.isRendering) {
    //     this.isRendering = false

    //     if (this.currentPageGL) this.currentPageGL.disactivated()
    //   }

    //   return
    // } else if (!this.isRendering) {
    this.isRendering = true


    if(this.controlsMain) this.controlsMain.update(); // A Commenter APRES RETRAVAILLE DE LA SPLINE
    // this.renderer.render(this.scene, this.currentCamera)
    this.composer.render()
  }

  /**
   * Called when the browser is resized.
   *
   * Adapts the Camera aspects and set a new size for the Renderer.
   *
   * Called by `App` (this method is throttled and called every 200ms).
   *
   * @returns {void}
   */
  resize() {
    this.camera.aspect = this.sizes.width / this.sizes.height
    WebGLManager.unitSize = WebGLManager.getUnitSize()
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }
}
