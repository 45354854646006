import { gsap } from 'gsap'
import Emitter from 'tiny-emitter'
export default class Intro {
  constructor({ skip = false} = {}) {
    this.bindMethods()
    this.getElems()

    if (!this.el) return

    this.page = null;
    this.skip = skip
    this.isReady = false;
    this.isReadyAll = false;
    this.hasEnded  = false;

    gsap.set(this.elLines, {
      opacity: 0,
      yPercent: 105
    })

    if (skip) this.el.style.display = 'none'
  }

  bindMethods() {
    this.loaded = this.loaded.bind(this)
    this.hide = this.hide.bind(this)
  }

  getElems() {
    this.el = document.querySelector('.js-intro')
    if (this.el) this.medias = this.el.querySelectorAll('.js-intro-media')
    this.elLines = this.el.querySelectorAll('.js-intro-title')
    this.elDots = this.el.querySelectorAll('.js-intro-dots')
    this.elLoading = this.el.querySelector('.js-loading')

    this.btnStart = this.el.querySelector('.js-start')
  }

  change() {
    this.tlChange = gsap.timeline()
    this.tlChange.to(this.el, {
      backgroundColor: '#000000',
      duration: 1.5
    }, 'start')

    this.tlChange.to(this.elLoading, {
      duration: 2.5,
      ease: 'power2.inOut',
      opacity: 0
    }, 'start')
  }

  hideBackground() {
    this.el.classList.add('loadedCanvas')

    gsap.to(this.el, {
      clearProps: 'all'
    })

    this.elDots.forEach((item, index) => {
      if(item.tl) item.tl.pause();
      gsap.to(item, {
        y: 0,
        opacity: 0,
        duration: 1,
        ease: 'power2.inOut',
        delay: 0.15 * index,
        onComplete: () => {
          if(index === 2) this.showBtn()
        }
      })
    });
  }

  showBtn() {
    gsap.to(this.btnStart, {
      opacity: 1,
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        this.btnStart.classList.remove('pt-none')
        this.isReadyAll = true
      }
    })
  }

  /**
   * Called when an image has been loaded.
   * Instantly resolves the image's Promise.
   *
   * @param {Event} event The event object.
   *
   * @returns {void}
   */
  loaded({ target }) {
    if (target.parentElement.classList.contains('js-intro-media') && target.parentElement.$onLoaded) target.parentElement.$onLoaded()
  }

  /**
   * Waits for all images to be loaded.
   * Then call .hide().
   * Then call .destroy().
   * Called by App.js.
   *
   * @returns {Promise} A Promise resolved when ALL images has been loaded, the div is hidden, and destroyed. OR it is resolved instantly if this.skip === true.
   */
  load() {
    if (!this.el || this.skip) return Promise.resolve()

    const promises = []

    return Promise.all(promises)
      .then(() => {

        gsap.set(this.elLines, {
          opacity: 0,
          yPercent: 105
        })

        return this.show()
      })
      // .then(this.hide)
      // .then(() => this.destroy())
  }

  /**
   * Show the Intro.
   *
   * @returns {Promise} A Promise resolved when the hide animation has ended.
   */
  show() {

    gsap.set(this.elLines, {
      opacity: 1,
      yPercent: 115
    })


    this.tlShow = gsap.timeline({
      onComplete: () => {
        this.hasEnded = true
        if(this.page) this.page.promiseResolve();
      }
    })

    this.tlShow.to(this.elLines, {
      duration: 2,
      ease: 'power2.inOut',
      yPercent: -5,
      stagger: 0.35
    }, 'start')

    this.tlShow.to(this.elDots, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      stagger: 0.1,
      ease: 'power2.inOut',
      onComplete: () => {
        this.dotAnimation();
      }
    }, 'start+=1.75')
  }

  dotAnimation() {
    this.elDots.forEach((item, index) => {
      item.tl = gsap.fromTo(item, {
          y: 0,
          opacity: 1
        }, {
          y: -15,
          opacity: 1,
          duration: 1,
          ease: 'power1.inOut',
          yoyo: true,
          repeat: -1,
          delay: 0.15 * index
        })
    });
  }


  /**
   * Hides the Intro.
   * Called when ALL images have been loaded.
   *
   * @returns {Promise} A Promise resolved when the hide animation has ended.
   */
  hide() {
    if(!this.isReadyAll) return

    this.tlHide = gsap.timeline({
      onStart: () => this.btnStart.classList.add('pt-none'),
      onComplete: () => this.page.showShader()
    })

    this.tlHide.to(this.btnStart, {
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut',
    }, 'start')

    this.tlHide.to(this.elLines, {
      duration: 2,
      ease: 'power2.inOut',
      yPercent: -115,
      stagger: {
        each: 0.35,
        from: 'end',
      }
    })
  }

  hideFinal() {
    return new Promise((resolve) => {
      if (!this.el) return resolve()

      const tl = gsap.timeline({
        delay: 0.25,
        onComplete: resolve
      })

      tl.to(this.el, {
        duration: 0.15,
        alpha: 0,
        display: 'none',
        ease: 'power2.inOut'
      })
    })
  }

  /**
   * Destroys the Intro.
   * Called when the Intro has been hidden.
   *
   * @returns {void}
   */
  destroy() {}
}