import {Clock} from 'three';
import Emitter from 'tiny-emitter'
import WebGLManager from './manager/WebGLManager';
import ScrollManager from './manager/ScrollManager';
import { throttle, debounce } from 'throttle-debounce'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Page from './page/HomeGL';
import Intro from './utils/Intro';
import gsap from 'gsap';
import Lenis from '@studio-freight/lenis'
import { getGPUTier } from 'detect-gpu';
import '../sass/index.scss';
gsap.registerPlugin(ScrollTrigger)

export default class App {
  constructor(options){
    this.container = options.dom;

    this.isSafari = false;

    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
      if (ua.indexOf('chrome') > -1) {
        this.isSafari = false;
      } else {
        this.isSafari = true;
      }
    }

    this.clock = new Clock()
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    this.mouse = {
      inertiaX: 0, inertiaY: 0
    }

    this.view = document.querySelector('main')
    
    this.bindMethods()

    this.promisedPerf = new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
    });

    this.detectGpu();

    this.tierPerf = 0;

    App.tierPerf = this.tierPerf;

    this.promisedPerf.then(() => {
      this.intro = new Intro({ skip: false})
      this.intro.change()
    }).then(() => {
      this.WebGLManager = new WebGLManager(this.container, this.sizes, !this.isLightVersion)
      this.WebGLManager.tier = this.tierPerf;
      // WebGLManager.isClicked = false;

      this.page = new Page();

      this.page.tier = !this.isVerylow
      this.page.veryLow = this.isVerylow && !this.isSafari

      

      this.page.intro = this.intro;

      Promise.all([this.page.isReady]).then(()=>{
        this.render();
        this.events();
        this.prepare();
        this.intro.load();
      }).then(()=> {
        this.ScrollManager = new ScrollManager()

        this.ScrollManager.lenis.scrollTo(0, { immediate: true })
      }).catch(error => {
        console.error(error) ;
      });
    })  
  }

  prepare() {
    return new Promise((resolve) => {
      const promises = [this.load()]

      if (this.page) promises.push(this.page.prepare())

      Promise.all(promises)
        .then(() => {
          if (this.page) gsap.delayedCall(0.15, resolve)
          else resolve()
        })
    })
  }

  load() {
    return Promise.resolve()
  }

  bindMethods() {
    this.render = this.render.bind(this)
    this.resize = this.resize.bind(this)
  }

  events() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('pointermove', this.pointermove)
  }

  detectGpu() {
    const array = ['intel iris plus', 'intel corporation iris graphics 6100', 'amd radeon pro 570 opengl engine']

    getGPUTier()
      .then((gpuTier) => {
        this.tierPerf = gpuTier.tier;
        if(gpuTier.fps) this.fps = gpuTier.fps
        this.isLightVersion = (array.indexOf(gpuTier.gpu) > -1 || (gpuTier.fps && gpuTier.tier < 3));
        this.isVerylow = (array.indexOf(gpuTier.gpu) > -1 || (gpuTier.fps && gpuTier.tier < 2));
      }).then(() => {
        this.promiseResolve();
      });

      
  }

  resize(){
    this.sizes.width = window.innerWidth
    this.sizes.height = window.innerHeight

    this.WebGLManager.resize();
    if(this.page) this.page.resize()
  }

  render(time, deltaTime){
    
    if(this.ScrollManager) {
      this.ScrollManager.render(
        time,
        deltaTime
      )
    }

    this.time = this.clock.getElapsedTime();
    this.delta = this.clock.getDelta();

    this.page.update(time);

    if(this.WebGLManager) {
      this.WebGLManager.render(
        this.time,
        this.delta
      )
    }

    window.requestAnimationFrame(this.render)
  }
}


new App({
  dom: document.querySelector('.js-webgl')
});