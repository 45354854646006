import { throttle, debounce } from 'throttle-debounce'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap';
import Lenis from '@studio-freight/lenis'
gsap.registerPlugin(ScrollTrigger)

export default class ScrollManager {
  constructor() {

    this.bindMethods()

    this.initScroll()

    this.getElems()

    this.render();
    this.events();

  }

  bindMethods() {
    this.scroll = this.scroll.bind(this)
    this.scroll = throttle(200, this.scroll)
    this.initScroll = this.initScroll.bind(this)
    // this.scrollEnded = this.scrollEnded.bind(this)
    // this.scrollEnded = debounce(100, this.scrollEnded)
  }

  events() {
    // this.lenis.on('scroll', this.scrollEnded)

    this.lenis.on('scroll', this.scroll)
  }

  getElems() {
    this.progress = document.querySelector('.js-progress')
  }

  scroll(e) {
    let progressToHundred = Math.round(e.progress * 100);

    if(progressToHundred < 1 ) {
      progressToHundred = 1
    }


    const progressToString = '' + progressToHundred + ''
    const finalProgress = progressToString.padStart(3, '0');

    this.progress.textContent = finalProgress;
    
    ScrollTrigger.update()
  }

  initScroll() {
    // this.lenis = new Lenis({
    //   duration: 2.4,
    //   infinite: true,
    // })

    this.domWrapper = document.querySelector('.wrapper')
    this.domScroll = document.querySelector('.js-content-scroll')

    this.lenis = new Lenis({
      duration: 2.4, wrapper: this.domWrapper, content: this.domScroll, infinite: true,
    })

    this.lenis.stop();

    ScrollManager.lenis = this.lenis


    this.initScrollTrigger(this.domWrapper)
  }

  initScrollTrigger(scrollContainer) {
    ScrollTrigger.defaults({
      scrub: true, scroller: scrollContainer
    })
  }

  render(time, deltaTime) {
    const lenisTime = time

    this.lenis.raf(lenisTime)
  }
}