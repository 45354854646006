import {
  Color
} from 'three';
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
gsap.registerPlugin(Draggable)
export default class Params {
  constructor({el, uniforms}) {
    this.el = el;
    this.uniforms = uniforms;

    this.init()
  }

  init() {
    this.getElems();
    this.bindMethods();

    this.createDraggable(this.el)
  }

  getElems() {
    this.track = this.el.querySelector('.input-range__track')
    this.thumb = this.el.querySelector('.input-range__thumb')
    this.fieldValue = this.el.querySelector('.input-range__value')
  }

  bindMethods() {
    this.createDraggable = this.createDraggable.bind(this)
  }

  createDraggable(element) {
    gsap.set(element, {
      display: 'block'
    })

    this.max = parseInt(element.dataset.max)
    this.name = element.dataset.name

    this.decimal = parseInt(element.dataset.decimal)
    this.valueInit = element.value ? parseFloat(element.value).toFixed(this.decimal) : parseFloat(element.dataset.value).toFixed(this.decimal)

    const draggable = Draggable.create(this.thumb, {
      type: 'x', bounds: element, zIndexBoost: false, onDrag: () => {
        this.progress = this.draggable.x / this.draggable.maxX
        this.updateValue(element, this.progress)
        this.updateTrack(element, this.progress)
      }
    })

    this.draggable = draggable[0]

    if (element.dataset.valueInit !== 0) this.set(element)
  }

  set(element) {
    const percent = element.dataset.valueInit / element.dataset.max
    const x = this.draggable.maxX * percent

    gsap.set(this.thumb, { x })
    this.updateValue(element, percent)
    this.updateTrack(element, percent)
    this.draggable.update()

  }

  updateValue(element, value) {
    const decimal = (value === 0 || value === 1) ? 0 : element.dataset.decimal

    this.value = parseFloat((value * element.dataset.max).toFixed(decimal))

    element.value = this.value
    
    this.fieldValue.innerText = this.value

    this.uniforms[element.dataset.nameUniform].value = this.value;
  }

  updateTrack(element, progress) {
    this.valueClip = (progress * 100).toFixed(0)

    gsap.set(this.track, { clipPath: 'polygon(0% 0%, ' + this.valueClip + '% 0%, ' + this.valueClip + '% 100%, 0% 100%)' })
  }

  resizeThumb() {
    const trackPercent = this.track.offsetWidth / 100;

    const x = trackPercent * this.valueClip

    gsap.set(this.thumb, { x })
  }

  resize() {
    this.draggable.update()

    this.resizeThumb()
  }

  destroy() {
  }
}