import WebGLManager from '../manager/WebGLManager'
import ScrollManager from '../manager/ScrollManager';
import Loader from '../utils/Loader'
import vertex from '../../shader/fragment/vertex.glsl?raw'
import frag from '../../shader/fragment/introFrag.glsl'
import MeshReflectorMaterial from '../utils/MeshReflectorMaterial';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {
  MeshBasicMaterial,
  sRGBEncoding,
  BoxGeometry,
  Mesh, 
  Vector3, 
  Vector2, 
  LineBasicMaterial, 
  CatmullRomCurve3,
  PlaneGeometry,
  Color,
  MeshLambertMaterial,
  TubeGeometry,
  MathUtils,
  DoubleSide,
  LineSegments,
  EdgesGeometry,
  MeshStandardMaterial,
  ACESFilmicToneMapping,
  ShaderMaterial,
  IcosahedronGeometry,
  Layers,
  Vector4
} from 'three';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

import Screen from '../components/Screen';
import Normal from '../../img/terrain-normal.jpg'
import Roughness from '../../img/terrain-roughness.jpg'
import city from '../../model/cityV4.glb'

import configScreen from '../utils/configScreen.js'
import Params from '../components/Params';
export default class HomeGL {
  constructor() {
    console.log('build')
    this.isReady = false;
    this.loaded = false;
    this.initScroll = false;
    this.prepare.bind(this);

    this.modelsToLoad = [city];
    this.texturesToLoad = [Normal, Roughness];
    this.screens = [];
    this.nulls = [];
    this.cityPart = [];
    this.screenComponents = [];
    this.numberBase = 0;
    this.numberBaseNull = 0;

    this.gui = null;
    this.splineConfig = false;
    this.initPointer = false;
    this.getElems();

    this.entire_scene = 0;
    this.bloom_scene = 1;
    this.darkMaterial = new MeshBasicMaterial( { color: new Color(0xffffff) } );
		this.materials = {};

    this.bindMethods()

    this.events();

    this.tier = false;
    this.veryLow = false;

    this.element = {
      rotation: { x: 0, y: 0, z: 0, w:0 }
    }

    this.sceneParams = {
      color: {r: 1, g: 0, b: 0.33 },
      progress: 0.5,
      x: 1,
      y: 0.45
    }

    this.bloomParams = {
      exposure: 1,
      strength: 0.1,
      threshold: 0,
      radius: 0.2
    }
  }

  bindMethods() {
    this.update = this.update.bind(this)
    this.darkenNonBloomed = this.darkenNonBloomed.bind(this)
    this.restoreMaterial = this.restoreMaterial.bind(this)
    this.renderBloom = this.renderBloom.bind(this);
    this.disposeMaterial = this.disposeMaterial.bind(this)
    this.show = this.show.bind(this)
    this.leave = this.leave.bind(this)
  }

  getElems() {
    this.return = document.querySelector('.js-return')
    this.rotatingSphere = document.querySelector('.js-sphere');
    this.pathSVG = document.querySelector('#pathTest');
    this.params = document.querySelector('.js-params-panel');
    this.contentEl = document.querySelector('.content');
    this.scrollc = document.querySelector('.js-content-scroll');
    this.btnStart = document.querySelector('.js-start');
    this.socialsElDom = document.querySelector('.socials')

    gsap.set(this.rotatingSphere, {
      yPercent: 120,
      opacity: 0
    })

    gsap.set(this.params, {
      xPercent: 100
    })

    WebGLManager.rotatingSphere = this.rotatingSphere;
    WebGLManager.socialsEl = this.socialsElDom;
  }

  events() {
    this.return.addEventListener('click', this.leave)
    this.btnStart.addEventListener('click', this.show)
  }

  ready({ models, textures }) {
    const readyPage = new Promise((resolve, reject) => {
      if(ScrollManager) ScrollManager.lenis.stop();


      // WebGLManager.instance.renderer.toneMapping = ACESFilmicToneMapping;
      WebGLManager.instance.renderer.outputEncoding = sRGBEncoding;
      WebGLManager.instance.scene.background = new Color(0x000002)
      this.models = models[0].scene;
      this.normalTexture = textures[0];
      this.roughnessTexture = textures[1]
      this.initName();
      this.screensClone = [...this.screens];
      this.nullsClone = [...this.nulls];
      this.models.scale.set(10,10,10);
      this.initCity(this.city)
      this.initPlane();
      this.initGround(this.tier);
      if(!this.veryLow) this.initBloom();
      WebGLManager.addToScene(this.models)  

      resolve();
    });

    readyPage.then(() => {
      this.initParamsComponent();
      this.updateMap();
      this.animateCam()
      this.cameraSC();
      this.introPlane()

      this.isReady = true;
      this.intro.isReady = true;
      this.intro.page = this

      WebGLManager.isClicked = true;

      if(this.intro.hasEnded) this.intro.hideBackground();
      else this.promisedIntro = new Promise((resolve, reject) => {
        this.promiseResolve = resolve;
      });

      if(this.promisedIntro){
        this.promisedIntro.then(() => {
          this.intro.hideBackground();
        })
      }
    });

    readyPage.catch((err) => {
      console.error(err, 'not ready') 
    });
  }

  leave() {
    if(WebGLManager.isAnimating) return;

    this.resetTl = gsap.timeline({
      onComplete: () => {
        WebGLManager.isClicked = false;
        ScrollManager.lenis.wrapperNode.classList.remove('pt-none')
        ScrollManager.lenis.start();
      }
    })

    this.resetTl.add(this.paramsComponent.hide(), 'start');

    this.resetTl.to(this.paramsComponent.el, {
      xPercent: 100,
      duration: 1.5,
      ease: 'power2.inOut',
      onStart: () => {
      },
      onComplete: () => {
        this.paramsComponent.divScrollInner.innerHTML = '';
      }
    }, 'start+=0.25')

    this.resetTl.to(WebGLManager.instance.camera.position, {
      x: WebGLManager.instance.camera.oldPosition.x,
      y: WebGLManager.instance.camera.oldPosition.y,
      z: WebGLManager.instance.camera.oldPosition.z,
      ease: 'power2.inOut',
      duration: 1.5
    }, 'start+=1.0')

    this.resetTl.to(WebGLManager.instance.camera.rotation, {
      x: WebGLManager.instance.camera.oldRotation.x,
      y: WebGLManager.instance.camera.oldRotation.y,
      z: WebGLManager.instance.camera.oldRotation.z,
      ease: 'power2.inOut',
      duration: 1.5
    }, 'start+=1.0')

    const zoom = { value : WebGLManager.instance.camera.zoom }
    this.resetTl.to(zoom, {
      value: 1,
      duration: 1.5,
      ease: 'power2.inOut',
      onUpdate: () => {
        WebGLManager.instance.camera.zoom = zoom.value;
        WebGLManager.instance.camera.updateProjectionMatrix();
      }
    }, 'start+=1.0')

    
    this.resetTl.to(WebGLManager.rotatingSphere, {
      yPercent: 0,
      duration: 1.5,
      ease: 'power2.inOut',
    }, 'start+=1.0')

    this.resetTl.to(WebGLManager.socialsEl, {
      opacity: 1,
      duration: 1.5,
      pointerEvents: 'all',
      ease: 'power2.inOut',
    }, 'start+=1.0')

  }

  show() {
    this.intro.hide()
  }

  showShader() {
    const uProgress = { value: 4 }
    const data = { progress: 0 }

    this.introPoints = [
      new Vector3(144.64223774569365, 11.184104521683725, -42.323052358771214),
      new Vector3(140.9861956192043, 2.5926559879651476, -55.067366860616616),
      new Vector3(135.45403888858735, 1.8283606452570422, -69.75512581652302),
      new Vector3(128.5164300586616, 1.25, -89.70970967882134)];

    this.curveELStart = new CatmullRomCurve3(this.introPoints);
    this.introAnimCam(this.curveELStart, 0)

    this.tlShowIntro = gsap.timeline({
      onStart: () =>  {
        this.isClicked = true;
        WebGLManager.isClicked = true;
      },
      onComplete: () => {
        this.intro.hideFinal()
        this.isClicked = false;
        this.initScroll = true;
        if(ScrollManager) ScrollManager.lenis.start();
      }
    })

    this.tlShowIntro.to(uProgress, {
      value: 0,
      duration: 2,
      ease: 'power1.out',
      onUpdate: () => { 
        this.planeIntro.material.uniforms.uProgress.value = uProgress.value
      },
      onComplete: () => {
        this.planeIntro.material.visible = false;
      }
    })

    this.tlShowIntro.to(data, {
      progress: 1,
      duration: 5,
      ease: 'power2.inOut',
      onUpdate: () => {
        this.introAnimCam(this.curveELStart, data.progress)
      },
      onComplete: () => {
        this.updateCamera(0);
      }
    });

    this.tlShowIntro.addLabel('map')

    this.tlShowIntro.to(this.socialsElDom, {
      opacity: 1,
      duration: 1,
      pointerEvents: 'all',
      ease: 'power2.inOut',
    }, 'map');

    this.tlShowIntro.to(this.rotatingSphere, {
      yPercent: 0,
      opacity: 1,
      duration: 1,
      ease: 'power2.inOut',
      onComplete: () => {
        WebGLManager.isClicked = false;
      }
    }, 'map');
  }

  introAnimCam(curve, progress) {
    this.positionIntro = new Vector3()
    curve.getPointAt(progress, this.positionIntro)

    WebGLManager.instance.camera.position.copy(this.positionIntro)

    this.timelineRotate = gsap.timeline({ paused: true })
    this.timelineRotate.fromTo(WebGLManager.instance.camera.rotation, {
      y: MathUtils.degToRad(120)
    }, {
      y: MathUtils.degToRad(19.3),
      ease: 'none'
    })
    this.timelineRotate.progress(progress)
  }

  introPlane() {
    this.planeIntroGeometry = new PlaneGeometry(1.7325, 0.775, 100, 100)
  
    this.uniforms = {
      u_time: { type: "f", value: 0 },
      uProgress: { value: 4.2 },
      uMagnitud: { value: 3.1 }
    };

    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vertex,
      side: DoubleSide,
      transparent: true,
      fragmentShader: frag
    });

    this.planeIntro = new Mesh(this.planeIntroGeometry, this.material)
    this.planeIntro.name = 'Loader'
    WebGLManager.addToCamera(this.planeIntro)
    
    this.planeIntro.position.z = -0.6
    this.planeIntro.material.needsUpdate = true;
    this.planeIntro.material.uniformsNeedUpdate = true;
  }

  initParamsComponent() {
    this.paramsComponent = new Params({el: this.params});
    WebGLManager.params = this.paramsComponent;
  }

  initBloom() {
    this.bloomLayer = new Layers();
    this.bloomLayer.set(this.bloom_scene);

    this.bloomElems = []
    this.screenComponents.forEach((screen) => {
      screen.elems.forEach((el) => {
        this.bloomElems.push(el)
      });
    });

    this.bloomComposer = new EffectComposer(WebGLManager.instance.renderer);
    this.bloomComposer.renderToScreen = false;
    this.bloomComposer.setSize(
      window.innerWidth * window.devicePixelRatio,
      window.innerHeight * window.devicePixelRatio
    );

    this.bloomPass = new UnrealBloomPass(new Vector2(window.innerWidth, window.innerHeight), 0.0, 0.0, 0.0);
    this.bloomPass.exposure = 0.5;
    this.bloomPass.threshold = 0.85;
    this.bloomPass.strength = 0.95;
    this.bloomPass.radius = 1.25;
    
    this.bloomComposer.addPass(WebGLManager.renderScene);
    this.bloomComposer.addPass(this.bloomPass);
    WebGLManager.composer.addPass(this.bloomPass)


    const finalPass = new ShaderPass(
      new ShaderMaterial({
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
        },
        vertexShader: `
        varying vec2 vUv;

        void main() {

          vUv = uv;

          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

        }
        `,
        fragmentShader: `
        uniform sampler2D baseTexture;
        uniform sampler2D bloomTexture;

        varying vec2 vUv;

        void main() {

          gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 0.0 ) * texture2D( bloomTexture, vUv ) );

        }
        `,
        defines: {}
      }),
      "baseTexture"
    );
    finalPass.needsSwap = true;
    const finalComposer = new EffectComposer(WebGLManager.instance.renderer);
    finalComposer.name = 'finalComposer';
    finalComposer.setSize(
      window.innerWidth * window.devicePixelRatio,
      window.innerHeight * window.devicePixelRatio
    );
    finalComposer.addPass(WebGLManager.renderScene);
    finalComposer.addPass(finalPass);

    WebGLManager.composer = finalComposer;

    this.setupBloom();
  }

  setupBloom() {
    WebGLManager.instance.scene.traverse( this.disposeMaterial );
      this.bloomElems.forEach((el) => {
        el.layers.enable(this.bloom_scene)
      })
  }

  renderBloom( mask ) {
    if ( mask === true ) {
      WebGLManager.instance.scene.traverse( this.darkenNonBloomed );
      this.bloomComposer.render();
      WebGLManager.instance.scene.traverse( this.restoreMaterial );
    } else {
      WebGLManager.instance.camera.layers.set( this.bloom_scene );
      this.bloomComposer.render();
      WebGLManager.instance.camera.layers.set( this.entire_scene );
    }
  }

  disposeMaterial( obj ) {
    if (obj.material) {
      obj.material.dispose();
    }
  }

  darkenNonBloomed( obj ) {
    if (obj.isMesh && this.bloomLayer.test(obj.layers) === false) {
      this.materials[obj.uuid] = obj.material;
      obj.material = this.darkMaterial;
    }
  }

  restoreMaterial( obj ) {
    if (this.materials[obj.uuid]) {
      obj.material = this.materials[obj.uuid];
      delete this.materials[obj.uuid];
    }
  }

  initName() {
    for(const child of this.models.children) {
      if(child.name.includes('Ground')) this.ground = child
      if(child.name.includes('city')) this.city = this.models.children[0];

      if(child.name.includes('Screen')) this.screens.push(child)
      if(child.name.includes('Null')) this.nulls.push(child)
    }
  }

  initPlane() {
    for (const screen of configScreen.elems) {
      const object = [];
      const objectNulls = [];
      for (let i = this.numberBase; i < screen.count + this.numberBase; i++) {
        object.push(this.screensClone[i])
      }

      for (let i = this.numberBaseNull; i < screen.countnull + this.numberBaseNull; i++) {
        objectNulls.push(this.nullsClone[i])
      }

      this.numberBase = this.numberBase + screen.count;
      this.numberBaseNull = this.numberBaseNull + screen.countnull;

      const el = new Screen({
        elems: object,
        nulls: objectNulls,
        frag: screen.frag,
        count: screen.count,
        name: screen.name,
        raycastRange: screen.raycastRange,
        yAngle: screen.yAngle,
        params: screen.params,
      })
      this.screenComponents.push(el)
    }
  }

  initGround(tier) {
    this.plane = new Mesh(new PlaneGeometry(1000, 1000))
    this.plane.position.y = 0.1
    this.plane.rotation.x = -Math.PI / 2
    WebGLManager.addToScene(this.plane)

    if(tier) {
      this.plane.material = new MeshReflectorMaterial(WebGLManager.instance.renderer, WebGLManager.camera, WebGLManager.instance.scene, this.plane, {
          resolution: 2048,
          blur: [0, 0],
          mixBlur: 1,
          mixContrast: 15,
          mirror: 1,
          mixStrength: 1.9,
          minDepthThreshold: 0.1,
          maxDepthThreshold: 1.2,
          depthScale: 0.5,
          depthToBlurRatioBias: 0,
          distortion: 0,
          mixContrast: 1.0125,
          reflectorOffset: 0,
      });

      this.plane.material.normalMap = this.normalTexture;
      this.plane.material.roughnessMap = this.roughnessTexture;
      this.plane.material.roughness = 0.49
      this.plane.material.normalScale = new Vector2(0.5, 0.5)
      this.plane.material.side = DoubleSide
      this.plane.material.needsUpdate = true;
      
    } else {
      this.plane.material = new MeshStandardMaterial({
        roughness: 0.8,
        metalness: 0.04,
        color: new Color(0x000000),
      })
      this.plane.material.normalMap = this.normalTexture;
      this.plane.material.roughnessMap = this.roughnessTexture;

      this.plane.material.normalScale = new Vector2(0.5, 0.5)
    }

  }

  initCity(el) {
    for(const child of el.children) {
      child.material = '';
      child.material = new MeshBasicMaterial({
        color: new Color(0x000000),
      })

      child.material.side = DoubleSide

      child.material.needsUpdate = true;

      const edges = new EdgesGeometry( child.geometry );

      const line = new LineSegments( edges, new LineBasicMaterial( { color: 0x353436, linewidth: 2 } ) );
      WebGLManager.addToScene(line)
    }
  }

  addTube(line) {
    const extrudePath = line;

    this.tubeGeometry = new TubeGeometry( extrudePath, 7500, 2, 3, true );

    const material = new MeshLambertMaterial( { color: 0xff00ff } );
  }

  cameraSC() {
    // if(!this.initScroll) return;

    const data = { progress: 0 }

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.scrollc,
        start: 'top+=1 top',
        end: 'bottom bottom',
        invalidateOnRefresh: true,
        scrub: true,
        ease: 'none',
        pinType: 'fixed',
        scroller: this.contentEl
      }
    });

    this.tl.to(data, {
      progress: 1,
      ease: 'none',
      onUpdate: () => {
        const progressCam = parseFloat((data.progress * 100).toFixed(2))
        WebGLManager.cameraProgress = progressCam;
        this.updateCamera(data.progress)
        this.updateMap((data.progress) * 100)
      }
    });
  }

  updateMap(progressCam) {
    gsap.set(this.pathSVG, { 
      drawSVG: '0' + progressCam  + '%',
      ease: 'none'
    });
  }

  animateCam() {

    this.direction = new Vector3();
    this.binormal = new Vector3();
    this.normal = new Vector3();
    this.position = new Vector3();
    this.lookAt = new Vector3();

    this.pointsCam = [
      new Vector3(128.5164300586616, 1.25, -89.70970967882134),
      new Vector3(124.11860184631033, 1.25, -102.27799533906654),
      new Vector3(119.87943584047682, 1.25, -116.19595222986034),
      new Vector3(115.79048432719763, 1.25, -131.670606576713),
      new Vector3(116.3881001589331, 1.25, -137.33553849286446),
      new Vector3(116.6889959474469, 1.25, -142.41981497208647),
      new Vector3(113.43772207953924, 1.25, -149.07773593946763),
      new Vector3(109.06981258720333, 1.25, -149.92972814223788),
      new Vector3(105.01818616408568, 1.25, -148.36254677594903),
      new Vector3(99.95461501710733, 1.25, -144.93772818603878),
      new Vector3(91.24210983918728, 1.25, -141.26855243977397),
      new Vector3(83.23765983454908, 1.25, -134.75724601917995),
      new Vector3(71.9362735104527, 1.25, -127.07242780799976),
      new Vector3(62.10889115872066, 1.25, -120.02528956727429),
      new Vector3(45.94628616419178, 1.25, -111.6244772604045),
      new Vector3(31.932914289242984, 1.25, -104.52413576540452),
      new Vector3(15.791107193768136, 1.25, -97.36591474673735),
      new Vector3(0.34583301012665735, 1.25, -91.67620870196968),
      new Vector3(-15.937859494061941, 1.25, -86.41402524860658),
      new Vector3(-31.931307646126562, 1.25, -79.77072173076222),
      new Vector3(-40.39866999163777, 1.25, -76.43902001561462),
      new Vector3(-44.44051654853354, 1.25, -73.39566691922445),
      new Vector3(-44.4637244114896, 1.25, -71.11260213525834),
      new Vector3(-42.25426012244181, 1.25, -67.58109625856135),
      new Vector3(-39.145646973622675, 1.25, -61.08651808581097),
      new Vector3(-34.74287892701095, 1.25, -51.632353589365636),
      new Vector3(-31.26031319214842, 1.25, -41.50768460701723),
      new Vector3(-27.311184547622723, 1.25, -30.775139239096465),
      new Vector3(-24.505408686181642, 1.25, -23.947734723572804),
      new Vector3(-19.85297381861088, 1.25, -14.279144379077126),
      new Vector3(-19.017289109964384, 1.25, -7.696085275859973),
      new Vector3(-16.967081431115666, 1.25, -1.6929377133258914),
      new Vector3(-11.398791737200067, 1.25, -0.33098788714912475),
      new Vector3(0.46542540689892953, 1.25, 2.7744216333278695),
      new Vector3(10.735131201568393, 1.25, 1.5923666756369794),
      new Vector3(24.060939323926018, 1.25, 0.7922433255470942),
      new Vector3(34.69584866856013, 1.25, -0.1370400469210562),
      new Vector3(48.17345979318366, 1.25, -5.699703845644766),
      new Vector3(55.50752003089282, 1.25, -5.774089414440169),
      new Vector3(70.87471700299402, 1.25, -5.714975125440644),
      new Vector3(75.63689133145017, 1.25, -3.0934222677015573),
      new Vector3(75.91618978872438, 1.25, 6.690710139162584),
      new Vector3(76.36010028646264, 1.25, 18.79989307053006),
      new Vector3(76.91115868743313, 1.25, 29.793554192123104),
      new Vector3(76.12326696622824, 1.25, 39.88116776667297),
      new Vector3(77.13196664704988, 1.25, 48.14927361574729),
      new Vector3(75.06255476734103, 1.25, 57.24307036092117),
      new Vector3(76.14142282548225, 1.25, 65.94038625390905),
      new Vector3(77.04936707709888, 1.25, 72.51893826107711),
      new Vector3(85.77478923247679, 1.25, 73.01958267692764),
      new Vector3(99.68862255878796, 1.25, 73.56388846028202),
      new Vector3(112.47940365125525, 1.25, 73.32533779113307),
      new Vector3(128.29540331431372, 1.25, 72.94822982956859),
      new Vector3(135.39847768304702, 1.25, 70.84267805687608),
      new Vector3(134.76171124391058, 1.25, 62.636593238712884),
      new Vector3(134.7904329026486, 1.25, 45.25873216183297),
      new Vector3(134.98190948835224, 1.25, 30.566822627563464),
      new Vector3(134.30087552398766, 1.25, 18.880335343128365),
      new Vector3(133.93685874089138, 1.25, 9.917210869718682),
      new Vector3(133.15414854934141, 1.25, 3.018388553683703),
      new Vector3(134.58019807357178, 1.25, -3.87729722332546),
      new Vector3(142.2482187700902, 1.25, -4.042272611474321),
      new Vector3(157.91139421334304, 1.25, -4.326081464080332),
      new Vector3(156.1785523671046, 1.25, -15.704497634898061),
      new Vector3(151.2036804713214, 1.25, -23.94440140211755),
      new Vector3(141.7940958958849, 1.25, -34.979019060842596),
      new Vector3(128.9297127348818, 1.25, -46.9954894761041),
      new Vector3(122.08819396787861, 1.25, -58.03019824657146),
      new Vector3(117.40054629323356, 1.25, -73.10355568334131),
      new Vector3(111.92187993038971, 1.25, -88.72221616120117),
      new Vector3(108.30101599136864, 1.25, -104.06842315472075),
      new Vector3(102.8639904506471, 1.25, -120.80520638902671),
      new Vector3(99.04001227590567, 1.25, -130.11353516779994),
      new Vector3(95.43354711057665, 1.25, -137.06060851101716),
      new Vector3(88.80222307894864, 1.25, -136.6716318603959),
      new Vector3(81.69181759458941, 1.25, -129.8997764547243),
      new Vector3(85.38313155060666, 1.25, -120.77737844402644),
      new Vector3(89.61124758592914, 1.25, -107.91184692177153),
      new Vector3(92.78067864013535, 1.25, -93.64483008033811),
      new Vector3(97.00884202573445, 1.25, -82.52584785499795),
      new Vector3(100.62392711542704, 1.25, -67.15608091310432),
      new Vector3(102.90640039670099, 1.25, -60.53746057111162),
      new Vector3(99.54118952721521, 1.25, -55.829098607100214),
      new Vector3(93.56346960770473, 1.25, -53.87513820096619),
      new Vector3(85.07742410795427, 1.25, -51.85004198047224),
      new Vector3(79.58006460593958, 1.25, -49.43978785569844),
      new Vector3(72.50699198139627, 1.25, -53.362156875605145),
      new Vector3(64.0846204805389, 1.25, -57.458675721173336),
      new Vector3(56.29875917519524, 1.25, -61.37532602162894),
      new Vector3(49.147673056567925, 1.25, -65.43672707538624),
      new Vector3(42.370442287307654, 1.25, -63.52971104155911),
      new Vector3(35.100274908584744, 1.25, -58.13597521070214),
      new Vector3(32.10545613440112, 1.25, -53.361048175315624),
      new Vector3(31.428174243868828, 1.25, -49.0696764830361),
      new Vector3(32.305732242908476, 1.25, -42.74748558641782),
      new Vector3(33.03289176291888, 1.25, -40.14052368043945),
      new Vector3(29.653518596780646, 1.25, -37.504169912582405),
      new Vector3(26.539540053485972, 1.25, -37.41044855723951),
      new Vector3(25.623781471065072, 1.25, -42.3938750239272),
      new Vector3(24.03351534485347, 1.25, -46.35465925351263),
      new Vector3(22.22087578098756, 1.25, -51.467249469538764),
      new Vector3(17.4743698460108, 1.25, -51.92684789852755),
      new Vector3(8.725523282537427, 1.25, -50.321068498259315),
      new Vector3(0.39093775250947227, 1.25, -46.75390653369835),
      new Vector3(-8.590475677608003, 1.25, -42.682435147969024),
      new Vector3(-17.67527380837599, 1.25, -35.358453630910546),
      new Vector3(-22.406885958802462, 1.25, -29.83079254258163),
      new Vector3(-24.317209788276934, 1.25, -25.37340383658821),
      new Vector3(-19.5881025781332, 1.25, -14.590656144831463),
      new Vector3(-17.45987656328759, 1.25, -8.64688495519137),
      new Vector3(-13.97590752938547, 1.25, -5.476955190709267),
      new Vector3(-8.590720788896176, 1.25, -6.885975963455925),
      new Vector3(0.12879945315339214, 1.25, -10.293067307850787),
      new Vector3(15.243498702169003, 1.25, -16.473222754623826),
      new Vector3(27.126780745224863, 1.25, -20.512622652687547),
      new Vector3(40.18173615340306, 1.25, -25.425142657526358),
      new Vector3(47.38327189969417, 1.25, -28.080529564308023),
      new Vector3(51.70393008168335, 1.25, -32.38404758098436),
      new Vector3(49.33578491342825, 1.25, -41.871048924290186),
      new Vector3(48.049803008055605, 1.25, -49.53126267443729),
      new Vector3(47.146229259496856, 1.25, -56.354380325368346),
      new Vector3(49.87511357742927, 1.25, -55.41474728779073),
      new Vector3(53.01664721300722, 1.25, -52.88816571122551),
      new Vector3(55.32918058994228, 1.25, -50.087648251455796),
      new Vector3(54.92543695927857, 1.25, -45.01428969765974),
      new Vector3(53.65718567649678, 1.25, -39.88500419997931),
      new Vector3(52.76520312505852, 1.25, -35.990913662258244),
      new Vector3(53.499894567664754, 1.25, -31.259766198562616),
      new Vector3(59.866039645615345, 1.25, -33.225635600276505),
      new Vector3(65.47782946695543, 1.25, -35.71008964126647),
      new Vector3(73.58370514640397, 1.25, -38.589923651150386),
      new Vector3(81.02004260253531, 1.25, -40.81742345429281),
      new Vector3(89.06912343395561, 1.25, -43.381329924305334),
      new Vector3(99.85038864427804, 1.25, -48.503107715616586),
      new Vector3(112.65701932544914, 1.25, -52.075439416623816),
      new Vector3(121.27288628924313, 1.25, -53.29641086950128),
      new Vector3(129.17424419807006, 1.25, -60.30734112844026),
      new Vector3(135.46073450754636, 1.25, -66.54290588656937),
      new Vector3(135.45403888858735, 1.25, -69.75512581652302),
      new Vector3(133.42727610205992, 1.25, -76.76152099481108),
      new Vector3(128.5164300586616, 1.25, -89.70970967882134)];

    // this.pointsCam = [
    //   new Vector3(24.46, 2.342, -58.438), new Vector3(26.653, 2.3287, -58.354),
    //   new Vector3(33.193, 2.3064, -58.074), new Vector3(43.254, 2.3407, -57.548),
    //   new Vector3(55.223, 4.5587, -55.08), new Vector3(66.363, 11.726, -48.803),

    //   new Vector3(77.768, 19.94, -41.709), new Vector3(89.136, 28.467, -34.382),
    //   new Vector3(100.01, 36.766, -27.269), new Vector3(109.87, 44.329, -20.794),
    //   new Vector3(118.22, 50.68, -15.371), new Vector3(128.2, 57.788, -9.298)
    // ];

      const curveCam = new CatmullRomCurve3(this.pointsCam);

      // const curveCamFinal = curveCam.getPoints( 500 );

      this.addTube(curveCam);
  }

  updateCamera(progress) {
    if(!this.tubeGeometry) return
    
    this.tubeGeometry.parameters.path.getPointAt( progress, this.position );
    this.position.multiplyScalar( 1 );

    this.tubeGeometry.parameters.path.getTangentAt( progress, this.direction );

    WebGLManager.instance.camera.position.copy( this.position );

    this.lookAt.copy( this.position ).add( this.direction );

    WebGLManager.instance.camera.matrix.lookAt( WebGLManager.instance.camera.position, this.lookAt, WebGLManager.instance.camera.up );
    WebGLManager.instance.camera.quaternion.setFromRotationMatrix( WebGLManager.instance.camera.matrix);
  }

  prepare() {
    WebGLManager.setCurrentPage(this)

    const promises = []

    if (this.loaded) {
      promises.push(Promise.resolve())
      promises.push(Promise.resolve())
    } else {
      promises.push(Loader.loadGLTF(this.modelsToLoad, true))
      promises.push(Loader.loadTexture(this.texturesToLoad))
    }

    return Promise.all(promises)
      .then((results) => {
        this.loaded = true

        if (!this.loadedModels) this.loadedModels = results[0]
        if (!this.loadedTextures) this.loadedTextures = results[1]

        // Change texture encoding to sRGBEncoding (taken from Don McDurdy GLTF Viewer)
        // https://github.com/donmccurdy/three-gltf-viewer/blob/7170005928c520b3a934bc7e4b653efaae1132bd/src/viewer.js#L394-L396
        if (this.loadedModels) {
          for (const model of this.loadedModels) {
            const scene = model.scene || model.scenes[0]

            if (scene) {
              scene.traverse((child) => {
                if (child.material) {
                  if (child.material.map) child.material.map.encoding = sRGBEncoding
                  if (child.material.emissiveMap) child.material.emissiveMap.encoding = sRGBEncoding
                  if (child.material.map || child.material.emissiveMap) child.material.needsUpdate = true
                }
              })
            }
          }
        }

        return {
          models: this.loadedModels,
          textures: this.loadedTextures,
        }
      })
      .then(this.ready.bind(this))
      .then(WebGLManager.activate)
  }
  
  update(time) {
    
    if(this.paramsComponent) this.paramsComponent.render(time)

    if(this.planeIntro && this.isClicked) this.uniforms.u_time.value += 0.0025; 

    if(!this.veryLow && this.bloomComposer) this.renderBloom(true);

    if(this.plane) {
      if(this.tier) this.plane.material.update();
    } 
    if(!this.screenComponents.length > 1) return

    for(const screen of this.screenComponents) {
      screen.update();
    }
  }

  resize() {
    if(WebGLManager.isClicked) this.leave()
    
    for(const screen of this.screenComponents) {
      screen.resize();
    }
  }
}
